<template>

</template>

<script>
import Galleria from "primevue/galleria";

export default {
  components: {
    Galleria,
  },
  data() {
    return {
      images: [],
    };
  },
  mounted() {},
  methods: {},
};
</script>


<style>

</style>