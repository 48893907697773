<template>
  <div class="card">
    <h4 class="text-center">Add images to your restaurant</h4>
    <Toast />
    <FileUpload
      name="demo[]"
      url="https://www.miamdeal.com/api/post"
      @upload="onAdvancedUpload"
      :multiple="true"
      accept="image/*"
      :maxFileSize="3000000"
      class="p-button-primary"
    >
      <template #empty>
        <p>Drag and drop files here to upload.</p>
      </template>
    </FileUpload>
  </div>

  <div class="grid-container">
    <div v-for="(img, index) in images" class="relative group check">
      <img
        class="grid-item"
        :class="'grid-item-' + (index + 1)"
        :src="baseurl + img.image"
        alt=""
      />
      <span class="absolute top-[-5px] right-[-5px]">
        <img src="../assets/delete.png" @click="deleteImage(img.id_image)" />
      </span>
      <button
        v-if="index !== 0"
        class="absolute opacity-0 group-hover:opacity-100 top-1/2 left-1/2 text-white font-bold py-2 px-4 transition-opacity duration-300 ease-in-out transform -translate-x-1/2 -translate-y-1/2"
      >
        <span class="">
          <img src="../assets/checked.png" @click="PrincipalImage(img.id_image)" />
        </span>
      </button>
    </div>
  </div>
</template>

<script>
import FileUpload from "primevue/fileupload";
import Toast from "primevue/toast";
import axios from "axios";

export default {
  components: {
    Toast,
    FileUpload,
  },
  data() {
    return {
      uploadUrl: "https://www.miamdeal.com/api/Owner/add-Restaurant-images/",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy", // Ensure this is the correct token to use
      id_restaurant: null, // Make sure this gets correctly set
      token: null, // This will be set from localStorage
      images: [],
      baseurl: "https://www.miamdeal.com/images/",
    };
  },
  mounted() {
    this.token = localStorage.getItem("auth_token");
    this.fetchRestaurantId();
  },
  methods: {
    PrincipalImage(id){
       const headers = {
        Authorization: `Bearer ${this.apiToken}`,
        "auth-token": this.token,
      };
      axios
        .get("https://www.miamdeal.com/api/Owner/principalimage/" + id, {
          headers,
        })

        .then((response) => {
          this.images = response.data.Images;
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Image Add Principal successfully.",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async deleteImage(id) {
      const headers = {
        Authorization: `Bearer ${this.apiToken}`,
        "auth-token": this.token,
      };
      axios
        .get("https://www.miamdeal.com/api/Owner/deleteimage/" + id, {
          headers,
        })

        .then((response) => {
          this.images = response.data.Images;
          this.$toast.add({
            severity: "success",
            summary: "Success",
            detail: "Image deleted successfully.",
            life: 3000,
          });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchRestaurantId() {
      axios
        .get("https://www.miamdeal.com/api/Owner/getrestaubyowner", {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": this.token,
          },
        })
        .then((response) => {
          // Ensure the response structure matches your expectations
          this.id_restaurant = response.data.Restaurants.id;
          this.images = response.data.Restaurants.Images;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async onAdvancedUpload(event) {
      const files = event.files;
      const formData = new FormData();
      files.forEach((file) => {
        formData.append("image[]", file);
      });

      const headers = {
        Authorization: `Bearer ${this.apiToken}`,
        "auth-token": this.token,
      };

      axios
        .post(this.uploadUrl + this.id_restaurant, formData, { headers })
        .then((response) => {
          console.log(response.data);
          this.images = response.data.Images;
          if (response.data.status === true) {
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Images Uploaded",
              life: 3000,
            });
          } else {
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: response.data.message,
              life: 3000,
            });
          }
        })
        .catch((error) => {
          console.error(error);
          this.$toast.add({
            severity: "error",
            summary: "Upload Error",
            detail: "Error uploading images",
            life: 3000,
          });
        });
    },
  },
};
</script>
<style>
.check:first-of-type {
  background-color: #32ba7c;
}
.grid-container {
  columns: 5 200px;
  column-gap: 1.5rem;
  width: 90%;
  margin: 0 auto;
  div {
    width: 150px;
    margin: 0 1.5rem 1.5rem 0;
    display: inline-block;
    width: 100%;
    padding: 5px;
    box-shadow: 1px 1px 7px rgba(0, 0, 0, 0.5);
    border-radius: 5px;
    transition: all 0.25s ease-in-out;
  }
}
.p-fileupload-buttonbar [type="button"] {
  background-color: #6366f1 !important;
}
</style>
