<template>
  <div class="col-span-1 lg:col-span-2 mt-8">
    <div class="card">
      <h5>Les derniers restaurants ajoutés</h5>
      <DataTable
        v-model:filters="filters"
        filterDisplay="menu"
        :value="restaurant"
        :rows="10"
        :paginator="true"
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="flex justify-between">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear Filters"
              outlined
              @click="clearFilter()"
              style="color: black; !important"
            />

            <span class="p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filters['global'].value"
                placeholder="Keyword Search"
                style="margin-right: 8px"
              />
              <Dropdown
                v-model="selectedstatus"
                @change="fetchFilteredData"
                :options="statusOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Status"
              />
            </span>
          </div>
        </template>
        <Column style="width: 8%">
          <template #header> Image </template>
          <template #body="slotProps">
            <OverlayPanel
              :ref="'op' + slotProps.index"
              style="width: 300px; height: 220px"
            >
              <img
                :src="baseurl + slotProps.data.Images[0].image"
                alt="Bamboo Watch"
              />
            </OverlayPanel>
            <img
              v-if="
                slotProps.data.Images &&
                slotProps.data.Images.length > 0 &&
                slotProps.data.Images[0].image
              "
              :src="baseurl + slotProps.data.Images[0].image"
              :alt="slotProps.data.name"
              width="50"
              class="shadow-lg border rounded-md"
              @click="toggle($event, slotProps.index)"
            />
            <img
              v-else
              :src="baseurl + '/Restaurants/defaultimg.png'"
              :alt="slotProps.data.name"
              width="50"
              class="shadow-lg border rounded-md"
              @click="toggle($event, slotProps.index)"
            />
          </template>
        </Column>
        <Column
          field="name"
          header="Name"
          :sortable="true"
          style="width: 15%"
        ></Column>
        <Column
          field="address"
          header="Address"
          :sortable="true"
          style="width: 15%"
        ></Column>
        <Column
          field="city"
          header="City"
          :sortable="true"
          style="width: 10%"
        ></Column>
        <Column
          field="phone"
          header="Phone number"
          :sortable="true"
          style="width: 15%"
        ></Column>

        <Column
          field="ownername"
          header="Owner name"
          :sortable="true"
          style="width: 15%"
        >
          <template #body="slotProps">
            <!-- <Avatar image="/images/avatar/asiyajavayant.png" class="mr-2" size="large" shape="circle" /> -->
            <Avatar
              icon="pi pi-user"
              class="mr-2"
              size="large"
              shape="circle"
            />

            {{ slotProps.data.ownername }}
          </template>
        </Column>
        <Column
          field="created_at"
          header="Created at"
          :sortable="true"
          style="width: 15%"
        ></Column>

        <Column header="Status" style="width: 15%">
          <template #body="slotProps">
            <Tag
              :severity="
                slotProps.data.status === 1
                  ? 'success'
                  : slotProps.data.status === 0
                  ? 'warning'
                  : 'danger'
              "
              :value="
                slotProps.data.status === 1
                  ? 'Approved'
                  : slotProps.data.status === 0
                  ? 'Pending'
                  : 'Rejected'
              "
            />
          </template>
        </Column>
        <Column style="width: 5%">
          <template #header> Actions </template>
          <template #body="slotProps">
            <div class="flex items-center">
              <Button
                icon="pi pi-eye"
                @click="() => showRestaurantDetails(slotProps.data.id)"
                severity="primary"
                text
                rounded
                aria-label="View Details"
              />

              <Button
                icon="pi pi-check"
                @click="() => confirm1(slotProps)"
                severity="success"
                text
                rounded
                aria-label="Filter"
              />
              <Button
                icon="pi pi-times"
                @click="() => confirm2(slotProps)"
                severity="danger"
                text
                rounded
                aria-label="Cancel"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <Toast />
      <ConfirmDialog></ConfirmDialog>
      <DynamicDialog style="width: 50vw" />
    </div>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Row from "primevue/row";
import axios from "axios";
import OverlayPanel from "primevue/overlaypanel";
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";
import { useConfirm } from "primevue/useconfirm";
import Avatar from "primevue/avatar";
import DynamicDialog from "primevue/dynamicdialog";

import { useDialog } from "primevue/usedialog";
import RestaurantDetails from "./Detailsdialog.vue";
import Dropdown from "primevue/dropdown";

export default {
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Button,
    OverlayPanel,
    Tag,
    InputText,
    Avatar,
    ConfirmDialog,
    Toast,
    DynamicDialog,
    Dropdown,
  },
  data() {
    return {
      restaurant: [],
      baseurl: "https://www.miamdeal.com/images/",
      apiUrl: "https://www.miamdeal.com/api/Admin/getrestaurant",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      visible: true,
      filters: {
        global: { value: null },
        status: { value: null },
      },
      statusOptions: [
        { label: "Approved", value: "Approved" },
        { label: "Rejected", value: "Rejected" },
        { label: "Pending", value: "Pending" },
      ],
      selectedstatus: null,
      originaldata: [],
    };
  },
  mounted() {
    this.Getdata();
  },
  methods: {
    toggle(event, index) {
      const overlayRef = this.$refs["op" + index];
      overlayRef.toggle(event);
    },
    clearFilter() {
      this.filters = {
        global: { value: null },
      };
      this.selectedstatus = null;
      this.restaurant = this.originaldata; // Restore the original data
    },
    Getdata() {
      const token = localStorage.getItem("auth_token");

      console.log("haihatoken " + token);
      axios
        .get(this.apiUrl, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": token,
          },
        })
        .then((response) => {
          this.restaurant = response.data.Restaurants;
          this.originaldata = response.data.Restaurants;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchFilteredData() {
      let filteredData = this.originaldata; // Create a copy of the original array

      if (this.selectedstatus !== null) {
        // Filter the copy of the array based on the selected status
        filteredData = filteredData.filter((item) => {
          if (this.selectedstatus === "Approved") {
            return item.status === 1; // Approved
          } else if (this.selectedstatus === "Rejected") {
            return item.status === 2; // Rejected
          } else if (this.selectedstatus === "Pending") {
            return item.status === 0; // Rejected
          } else {
            return true; // Show all when no specific status is selected
          }
        });
      }

      this.restaurant = filteredData; // Assign the filtered array back to the main data array
    },
  },
  setup() {
    const confirm = useConfirm();
    const toast = useToast();
    let changeStatusResponse = null;
    const dialog = useDialog();

    // const openDialog = (restaurant) => {
    //     const dialogRef = dialog.open(RestaurantDetails, {
    //         props: {
    //             restaurant: restaurant.id,

    //         }
    //     });
    // };

    const confirm1 = (slotProps) => {
      const { data } = slotProps;

      confirm.require({
        message: "Are you sure you want to proceed with ?" + data.name,
        header: "Confirmation",
        icon: "pi pi-exclamation-triangle",
        acceptClass: "p-button-info",

        accept: () => {
          console.log("Accepted confirmation for ID:", data.id);

          ChangeStatus(data.id, 1).then((response) => {
            changeStatusResponse = response;
            console.log(changeStatusResponse);

            if (changeStatusResponse && changeStatusResponse.status === true) {
              data.status = 1;
              toast.add({
                severity: "info",
                summary: "Confirmed",
                detail: "The Status has been updated",
                life: 500,
              });
            }
          });
        },
      });
    };

    const confirm2 = (slotProps) => {
      const { data } = slotProps;

      confirm.require({
        message: "Do you want to delete this record?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          console.log("Accepted confirmation for ID:", data.id);

          ChangeStatus(data.id, 0).then((response) => {
            changeStatusResponse = response;
            console.log(changeStatusResponse);

            if (changeStatusResponse && changeStatusResponse.status === true) {
              data.status = 0;

              toast.add({
                severity: "info",
                summary: "Confirmed",
                detail: "The Status has been updated",
                life: 500,
              });
            }
          });
        },
      });
    };

    const ChangeStatus = (id, status) => {
      const url = "https://www.miamdeal.com/api/Admin/change-Restaurant-Status";
      const token = localStorage.getItem("auth_token");
      const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "auth-token": token,
        },
      };

      const params = {
        id_restaurant: id,
        status: status,
      };

      return axios

        .post(url, null, { params, headers: config.headers })
        .then((response) => {
          return response.data;

          // console.log(response.data);
          // this.Getdata();
        })

        .catch((error) => {
          console.log(error);
        });
    };
    const showRestaurantDetails = (id) => {
      localStorage.setItem("iddetails", id);
      console.log("lid howa hada :" + id);
      dialog.open(RestaurantDetails, {
        props: {
          restaurantId: id,
          style: {
            width: "50vw",
          },
          breakpoints: {
            "960px": "75vw",
            "640px": "90vw",
          },
          modal: true,
        },
      });
    };

    return {
      confirm1,
      confirm2,
      ChangeStatus,
      changeStatusResponse,
      showRestaurantDetails,
      // Bind the method to the setup context
    };
  },
};
</script>
