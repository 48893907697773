<template>
  <div class="mx-auto max-w-screen-xl px-2 pt-4 pb-12 mt-4">
    <div class="flex flex-row justify-between mb-6 px-5">
      <h1 class="text-3xl text-black font-normal">
        Restaurants populaires à Marrakech
      </h1>
      <div class="flex flex-row gap-2">
        <button class="buttn-prev bg-gray-100 rounded-md">
          <font-awesome-icon
            class="text-gray-600 px-2 py-0"
            icon="fa-solid fa-chevron-left"
          />
        </button>
        <button class="buttn-next bg-gray-100 rounded-md">
          <font-awesome-icon
            class="text-gray-600 px-2 py-0"
            icon="fa-solid fa-chevron-right"
          />
        </button>
      </div>
    </div>

    <swiper
      :modules="modules"
      grab-cursor:true
      :slides-per-view="1"
      :breakpoints="{
        '640': {
          slidesPerView: 1,
          spaceBetween: 10,
        },
        '768': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }"
      :pagination="{ el: '.swiper-pagination', clickable: true }"
      :navigation="{
        nextEl: '.buttn-next',
        prevEl: '.buttn-prev',
        clickable: true,
      }"
      class="swiper_container"
    >
      <swiper-slide v-for="card in filteredRestaurants">
        <router-link :to="`/restaurants/${card.id}`">
          <div class="text-left rounded-lg p-5 space-y-1 relative">
            <img
              class="object-cover rounded-md"
              v-if="
                card.Images && card.Images.length > 0 && card.Images[0].image
              "
              :src="baseurl + card.Images[0].image"
              alt=""
              style="width: 361.319px; height: 237.951px"
            />
            <img
              class="h-50 w-full object-cover rounded-md"
              v-else
              src="https://www.miamdeal.com/images/Restaurants/646669f354d3d.jpg"
              alt="Default Image"
            />
            <div class="absolute top-0 right-0">
              <font-awesome-icon
                class="fa-3x text-[#4CD964]"
                icon="fa-solid fa-star"
              />
              <span
                class="absolute top-3 right-[15px] text-white text-lg font-medium"
                >{{ card.avg_rating }}</span
              >
            </div>
            <p
              class="text-black font-thin italic text-xl uppercase"
              v-if="
                card.categories_restaurant &&
                card.categories_restaurant.length > 0 &&
                card.categories_restaurant[0].category_name
              "
            >
              {{ card.categories_restaurant[0].category_name }}
            </p>
            <p class="text-black font-thin italic text-xl uppercase" v-else>
              Moroccan
            </p>
            <p class="mt-2 text-2xl font-semibold text-black">
              {{ card.name }}
            </p>
            <p class="mt-2 mb-4 font-normal text-xl text-black max-w-[150px]">
              <span>{{ card.address }}</span
              ><br /><span>{{ card.city }}</span>
            </p>
            <p
              class="text-[#9EB23B] font-semibold text-2xl"
              v-if="card.avg_price"
            >
              {{ card.avg_price }}<span class="ml-1 mr-2">dh</span
              ><span class="font-normal text-lg">Prix moyen</span>
            </p>
            <p class="text-white bg-[#9EB23B] p-1 rounded-lg max-w-min">20%</p>
          </div>
        </router-link>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Pagination, Navigation } from "swiper";
import "swiper/swiper-bundle.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import axios from "axios";

export default {
  data() {
    return {
      baseurl: "https://www.miamdeal.com/images/",
      apiUrl: "https://www.miamdeal.com/api/Restaurant/get-main-Restaurants",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      cards: [],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    filteredRestaurants() {
      return this.cards.filter((restaurant) => restaurant.city === "Marrakesh");
    },
  },
  mounted() {
    axios
      .get(this.apiUrl, {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
      })
      .then((response) => {
        this.cards = response.data.Restaurants;
        // console.log(this.cards)
      })
      .catch((error) => {
        console.log(error);
      });
  },
  setup() {
    return {
      Swiper,
      modules: [Navigation, Pagination],
    };
  },
};
</script>

<style scoped></style>
