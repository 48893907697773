<template>
  <div>
    <div v-if="restaurant.Images && restaurant.Images.length > 0">
      <!-- <swiper
        :slidesPerView="3"
        :spaceBetween="0"
        :freeMode="true"
        :pagination="{ clickable: true }"
        :modules="modules"
        :navigation="true"
        class="mySwiper"
      >
        <swiper-slide v-for="img in restaurant.Images">
          <img class="w-full h-72" :src="baseurl + img.image" />
        </swiper-slide>
      </swiper> -->

      <div class="card flex justify-center">
        <Galleria
          :value="restaurant.Images"
          :responsiveOptions="responsiveOptions"
          :numVisible="5"
          :circular="true"
          containerStyle="max-width: 640px"
          :showItemNavigators="true"
        >
          <template #item="slotProps">
            <img
              :src="baseurl + slotProps.item.image"
              style="width: 640px; display: block; height: 340px"
            />
          </template>
          <template #thumbnail="slotProps">
            <img
              :src="baseurl + slotProps.item.image"
              style="width: 90px; display: block"
            />
          </template>
        </Galleria>
      </div>
    </div>
    <div v-else>
      <swiper
        :slidesPerView="3"
        :spaceBetween="0"
        :freeMode="true"
        :pagination="{ clickable: true }"
        :modules="modules"
        :navigation="true"
        class="mySwiper"
      >
        <swiper-slide>
          <img class="w-full h-72" src="../assets/pic1.jpg" />
        </swiper-slide>
        <swiper-slide>
          <img class="w-full h-72" src="../assets/pic3.jpg" />
        </swiper-slide>
        <swiper-slide>
          <img class="w-full h-72" src="../assets/pic2.jpg" />
        </swiper-slide>
        <swiper-slide>
          <img class="w-full h-72" src="../assets/pic4.jpg" />
        </swiper-slide>
        <swiper-slide>
          <img class="w-full h-72" src="../assets/pic5.jpg" />
        </swiper-slide>
      </swiper>
    </div>

    <div class="container mx-auto flex justify-center py-10">
      <div class="mx-5">
        <div class="grid grid-cols-1 sm:grid-cols-2">
          <div class="">
            <h1 class="text-3xl font-bold mb-4">{{ restaurant.name }}</h1>
            <div class="mb-4 flex flex-row items-center gap-2">
              <span class="bg-[#DDF0D6] w-fit px-2 py-1 rounded-md">
                <font-awesome-icon
                  class="w-5 h-5 text-[#9EB23B]"
                  icon="fa-solid fa-location-dot"
                />
              </span>
              <p v-if="restaurant && restaurant.address && restaurant.city">
                {{ restaurant.address + ", " }}
                <span>{{ restaurant.city }}</span>
              </p>
              <p v-else>
                <span
                  class="h-2.5 bg-gray-200 rounded-full animate-pulse w-44"
                ></span>
              </p>
            </div>
            <div class="mb-4 flex flex-row items-center gap-2">
              <span class="bg-[#DDF0D6] w-fit px-2 py-1 rounded-md">
                <font-awesome-icon
                  class="w-5 h-5 text-[#9EB23B]"
                  icon="fa-solid fa-utensils"
                />
              </span>
              <p
                v-if="
                  restaurant &&
                  restaurant.categories_restaurant &&
                  restaurant.categories_restaurant.length
                "
              >
                {{ restaurant.categories_restaurant[0].category_name }}
              </p>
              <p v-else>
                <span
                  class="h-2.5 bg-gray-200 rounded-full animate-pulse w-40"
                ></span>
              </p>
            </div>
            <div class="mb-4 flex flex-row items-center gap-2">
              <span class="bg-[#DDF0D6] w-fit px-2 py-1 rounded-md">
                <font-awesome-icon
                  class="w-5 h-5 text-[#9EB23B]"
                  icon="fa-solid fa-money-bill"
                />
              </span>
              <p v-if="restaurant && restaurant.Menu">
                {{ formatPrice(restaurant.Menu.avg_price) + " DH" }}
              </p>
              <p v-else>
                <span
                  class="h-2.5 bg-gray-200 rounded-full animate-pulse w-24"
                ></span>
              </p>
            </div>
            <div class="mb-6">
              <span class="text-gray-700 font-medium text-2xl">Offres</span>
              <div v-if="Offres.length > 0" v-for="offre in Offres">
                <div
                  v-if="isWithinDateRange(offre.start_date, offre.end_date)"
                  class="card bg-gray-100 py-4 px-6 rounded-lg shadow-lg"
                >
                  <div class="flex justify-between items-center">
                    <span>
                      <b class="text-[#9EB23B] pe-2">{{menu[offre.menu_id].name}}</b>
                      <p
                        class="text-white bg-[#9EB23B] p-1 rounded-lg inline-block"
                        @click="test"
                      >
                        -{{ offre.discount_value
                        }}{{ offre.discount_type === "Fixed" ? " DH" : " %" }}
                      </p>
                    </span>

                    <!-- <p class="font-semibold text-sm text-gray-700 ml-2">
                    {{ offre.discount_type }}
                  </p> -->
                    <p class="text-sm text-gray-500">
                      <i class="pi pi-check-circle mr-1"></i>
                      <strong>Min Uses:</strong>
                      {{
                        offre.min_uses !== null ? offre.min_uses : "Unlimited"
                      }}
                    </p>
                  </div>
                  <p class="font-bold text-gray-500">
                    {{ offre.description }}
                  </p>
                </div>
              </div>
              <div v-else>
                <p
                  class="text-white bg-[#9EB23B] py-1 px-2 rounded-lg inline-block mt-5"
                >
                  Theres no Offres in this restaurant
                </p>
              </div>
            </div>
            <div class="mb-4">
              <span class="text-gray-700 font-medium text-3xl">Menu</span>
              <div v-if="isLoading">
                <div
                  v-for="index in skeletonCount"
                  :key="index"
                  class="flex pt-4 items-center justify-between"
                >
                  <div>
                    <div class="h-2 bg-gray-300 rounded-full w-24 mb-4"></div>
                    <div class="w-52 h-2 bg-gray-200 rounded-full"></div>
                  </div>
                  <div class="h-2.5 bg-gray-300 rounded-full w-12"></div>
                </div>
              </div>
              <div v-else>
                <div
                  v-for="category in menuItems"
                  :key="category.id_category"
                  class="my-6"
                >
                  <h3 class="text-2xl font-semibold mb-4">
                    {{ category.category_name }}
                  </h3>
                  <div class="grid grid-cols-1 gap-6">
                    <div
                      v-for="item in category.items"
                      :key="item.id"
                      class="bg-white rounded-lg shadow-md p-6"
                    >
                      <div class="flex justify-between mb-4">
                        <div>
                          <p class="font-bold text-gray-800">
                            {{ item.item_name }}
                          </p>
                          <p class="text-gray-500">{{ item.description }}</p>
                        </div>
                        <p class="font-semibold text-gray-800">
                          {{ item.price }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mb-4">
              <span class="text-gray-700 font-medium text-3xl">Reviews</span>
              <div class="bg-[#F9FAFA] rounded-lg p-6 my-6">
                <div class="flex items-center">
                  <div class="w-1/4">
                    <div class="circle-wrap">
                      <div class="circle">
                        <div class="mask full">
                          <div class="fill"></div>
                        </div>
                        <div class="mask half">
                          <div class="fill"></div>
                        </div>
                        <div class="inside-circle">
                          <span class="text-md">{{
                            Math.round(restaurant.avg_rating) * 2
                          }}</span
                          ><span class="text-sm text-gray-600">/10</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="h-px bg-gray-200"></div>
                  <!-- ................ -->
                  <div class="w-3/4">
                    <div
                      v-for="rating in ratings"
                      :key="rating.value"
                      class="flex items-center justify-end w-full mb-2"
                    >
                      <span class="text-sm font-medium text-black">{{
                        rating.value
                      }}</span>
                      <div class="w-2/4 h-3 mx-4 bg-[#DDF0D6] rounded-full">
                        <div
                          class="h-3 bg-[#9EB23B] rounded-full"
                          :style="{ width: rating.percent }"
                        ></div>
                      </div>
                    </div>
                  </div>

                  <!-- ................ -->
                </div>
                <div class="border border-gray-400 p-4 mt-4 rounded-lg">
                  <div class="flex flex-row gap-2 items-center">
                    <img src="../assets/reviews-verified.69d8066e.svg" />
                    <p class="font-semibold text-lg">
                      Real experience from real diners
                    </p>
                  </div>
                  <p class="ml-8 text-sm">
                    Only people who have booked with MiamDeal can leave a rating
                    and review.
                  </p>
                </div>
              </div>
              <div class="my-6">
                <div v-if="isLoading">
                  <div
                    v-for="index in skeletonCount"
                    :key="index"
                    class="flex items-center mt-4 space-x-3"
                  >
                    <svg
                      class="text-gray-200 w-14 h-14"
                      aria-hidden="true"
                      fill="currentColor"
                      viewBox="0 0 20 20"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-6-3a2 2 0 11-4 0 2 2 0 014 0zm-2 4a5 5 0 00-4.546 2.916A5.986 5.986 0 0010 16a5.986 5.986 0 004.546-2.084A5 5 0 0010 11z"
                        clip-rule="evenodd"
                      ></path>
                    </svg>
                    <div>
                      <div
                        class="h-2.5 bg-gray-200 rounded-full w-32 mb-2"
                      ></div>
                      <div class="w-48 h-2 bg-gray-200 rounded-full"></div>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <div class="grid gap-6">
                    <div
                      v-for="review in reviews"
                      :key="review.id"
                      class="bg-white border rounded-lg shadow-sm p-6"
                    >
                      <div class="flex items-center justify-between mb-4">
                        <div class="flex items-center mb-4">
                          <img
                            src="https://w7.pngwing.com/pngs/481/915/png-transparent-computer-icons-user-avatar-woman-avatar-computer-business-conversation-thumbnail.png"
                            alt="User Avatar"
                            class="w-10 h-10 rounded-full mr-4"
                          />
                          <div>
                            <p class="text-gray-800 text-lg font-semibold">
                              {{ review.name }}
                            </p>
                            <p class="text-xs text-gray-500">
                              {{ review.created_at }}
                            </p>
                          </div>
                        </div>
                        <div class="flex items-center">
                          <p class="text-3xl text-gray-900">
                            {{ review.rating }}
                          </p>
                          <span class="text-sm text-gray-500">/5</span>
                        </div>
                      </div>
                      <p class="text-gray-700">{{ review.comment }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="">
            <div class="sticky top-20">
              <!-- <div class="bg-white p-4 rounded shadow">
                <h2 class="text-xl font-bold mb-4">Réservez votre table</h2>
                <div class="mb-4">
                  <label for="date1" class="text-gray-700">Date:</label>
                  <Calendar
                    v-model="date"
                    :minDate="minDate"
                    class="w-full px-3 py-2"
                    touchUI
                  />
                  <span v-if="!err_date.valid" class="text-red-500">{{
                    err_date.msg
                  }}</span>

                </div>
                <div class="mb-4">
                  <label for="time" class="text-gray-700">Heure:</label>
                  <Calendar
                    id="calendar-timeonly"
                    v-model="time"
                    timeOnly
                    class="w-full px-3 py-2"
                  />
                  <span v-if="!err_time.valid" class="text-red-500">{{
                    err_time.msg
                  }}</span>
                </div>

                <div class="mb-4">
                  <label for="party-size" class="text-gray-700"
                    >Combien d'invités?</label
                  >
                  <InputNumber
                    v-model="value1"
                    inputId="stacked-buttons"
                    showButtons
                    class="w-full px-3 py-2"
                  />
                  <span v-if="!err_per.valid" class="text-red-500">{{
                    err_per.msg
                  }}</span>
                </div>
                <div class="mb-4">
                  <label for="party-size" class="text-gray-700"
                    >Demande Spéciale</label
                  >
                  <Textarea
                    class="w-full px-3 py-2"
                    v-model="sprequest"
                    autoResize
                    rows="5"
                    cols="30"
                  />
                    <span v-if="!err_demande.valid" class="text-red-500">{{
                    err_demande.msg
                  }}</span>
                </div>

                <button
                  @click="handelClick"
                  class="bg-[#9EB23B] text-white px-4 py-2 rounded-md"
                >
                  Reservez maintenant
                </button>

                <Teleport to="body">
                  <modal
                    :show="showModal"
                    @close="showModal = false"
                    @open-login2="openLoginModal"
                  >
                  </modal>
                  <modaltwo
                    v-if="showLoginModal"
                    @close="showLoginModal = false"
                    @open-signup2="openSignUpModal"
                  >
                  </modaltwo>
                </Teleport>
              </div> -->
              <FormBooking :menu="selectmenu" />
            </div>
          </div>
        </div>
      </div>
    </div>
    <LastSection />
  </div>
</template>

<script>
import "swiper/swiper-bundle.css";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Navigation, Pagination, FreeMode } from "swiper";
import axios from "axios";
import Button from "primevue/button";
import Divider from "primevue/divider";
import LastSection from "../components/HowItWorks.vue";
import Skeleton from "primevue/skeleton";
import DynamicDialog from "primevue/dynamicdialog";
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import Modal from "../components/ModalSignUp2.vue";
import Modaltwo from "../components/ModalSignIn2.vue";
import Swal from "sweetalert2";

import FormBooking from "@/components/FormBooking.vue";
import Galleria from "primevue/galleria";

export default {
  data() {
    return {
      restaurant: [],
      baseurl: "https://www.miamdeal.com/images/",
      apiUrl: "https://www.miamdeal.com/api/Restaurant",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      menuItems: [],
      menu: [],
      selectmenu: [],
      skeletonCount: 5,
      isLoading: true,
      reviews: [],
      date: null,
      time: null,
      err_date: { valid: true, msg: null },
      err_time: { valid: true, msg: null },
      err_per: { valid: true, msg: null },
      err_demande: { valid: true, msg: null },
      value1: 1,
      isauth: false,
      averageRating: 4.5,
      ratings: [],
      sprequest: "",
      minDate: null,
      Offres: [],
      data: null,

      showModal: false,
      showLoginModal: false,
      items: [],
      responsiveOptions: [
        {
          breakpoint: "1300px",
          numVisible: 4,
        },
        {
          breakpoint: "575px",
          numVisible: 1,
        },
      ],
    };
  },
  components: {
    Swiper,
    SwiperSlide,
    Calendar,
    InputNumber,
    Button,
    Divider,
    Skeleton,
    Textarea,
    LastSection,
    Galleria,

    Modal,
    Modaltwo,
    DynamicDialog,
    FormBooking,
  },
  computed: {},
  methods: {
    isWithinDateRange(start_date, end_date) {
      const today = new Date().setHours(0, 0, 0, 0);
      const startDate = new Date(start_date).setHours(0, 0, 0, 0);
      const endDate = new Date(end_date).setHours(0, 0, 0, 0);
      return today >= startDate && today <= endDate;
    },
    openLoginModal() {
      this.showModal = false;
      this.showLoginModal = true;
    },
    openSignUpModal() {
      this.showModal = true;
      this.showLoginModal = false;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    formatPrice(price) {
      return parseFloat(price).toFixed(2);
    },
    getratingPercentages() {
      const ratingsCount = [0, 0, 0, 0, 0]; // Initialize the ratingsCount array with zeros
      const totalRatings = this.reviews.length; // Total number of ratings
      for (const review of this.reviews) {
        const rating = review.rating;
        if (rating >= 1 && rating <= 5) {
          ratingsCount[rating - 1]++; // Increment the count for the corresponding rating
        }
      }

      const ratingPercentages = ratingsCount.map(
        (count) => ((count / totalRatings) * 100).toFixed(0) + "%"
      );

      this.ratings = [
        { value: 5, percent: ratingPercentages[4] },
        { value: 4, percent: ratingPercentages[3] },
        { value: 3, percent: ratingPercentages[2] },
        { value: 2, percent: ratingPercentages[1] },
        { value: 1, percent: ratingPercentages[0] },
      ];
    },
    handelClick() {
      if (
        this.date === null ||
        this.time === null ||
        this.value1 < 1 ||
        this.value1 > 10 ||
        this.sprequest.length > 400
      ) {
        if (this.date === null) {
          this.err_date = {
            valid: false,
            msg: "Date cannot be null. Please select a date.",
          };
        } else {
          this.err_date = { valid: true, msg: null };
        }
        if (this.time === null) {
          this.err_time = {
            valid: false,
            msg: "Time cannot be null. Please select a time.",
          };
        } else {
          this.err_time = { valid: true, msg: null };
        }

        if (this.value1 < 1 || this.value1 > 10) {
          this.err_per = {
            valid: false,
            msg: "select betewen 1 to 10.",
          };
        } else {
          this.err_per = { valid: true, msg: null };
        }

        if (this.sprequest.length > 400) {
          this.err_demande = {
            valid: false,
            msg: "400 the max caracter.",
          };
        } else {
          this.err_demande = { valid: true, msg: null };
        }
      } else {
        this.err_date = { valid: true, msg: null };
        this.err_time = { valid: true, msg: null };
        this.err_per = { valid: true, msg: null };
        this.err_demande = { valid: true, msg: null };
        if (localStorage.getItem("auth_token") != null) {
          this.Make_booking();
        } else {
          Swal.fire({
            title: "tu es sûre ?",
            text: "Soumettez votre compte pour compléter votre réservation",
            icon: "warning",
            showCancelButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            cancelButtonText: "Non!",
            confirmButtonText: "Oui, connectez-vous!",
          }).then((result) => {
            if (result.isConfirmed) {
              this.showModal = true;

              setTimeout(() => {
                Swal.fire({
                  text: "Vous continuez à réserver ?",
                  icon: "question",
                  showCancelButton: true,
                  background: "#dee2e6",
                  backdrop: `transparent`,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  cancelButtonText: "Anuller",
                  confirmButtonText: "Contenu",
                }).then((result) => {
                  if (result.isConfirmed) {
                    if (localStorage.getItem("auth_token") != null) {
                      this.Make_booking();
                    } else {
                      this.showModal = true;
                    }
                  }
                });
              }, 1000);
            }
          });
        }
      }
    },
    Make_booking() {
      console.log("Date: " + this.date);
      console.log("Time: " + this.time);
      const mysqlDateFormat = this.date.toISOString().substr(0, 10);
      console.log(mysqlDateFormat);

      const restaurantId = this.$route.params.id;
      const url = "https://www.miamdeal.com/api/Booking/make-booking";
      const token = localStorage.getItem("auth_token");
      const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "auth-token": token,
        },
      };

      const params = {
        restaurant_id: restaurantId,
        date_booking: mysqlDateFormat,
        time_booking: this.time,
        party_size: this.value1,
        special_request: this.sprequest,
      };

      axios

        .post(url, null, { params, headers: config.headers })
        .then((response) => {
          // console.log(response.data);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
        })

        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    const restaurantId = this.$route.params.id;
    this.minDate = new Date();
    axios
      .get(`${this.apiUrl}/get-details-Restaurant/${restaurantId}`, {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
        },
      })
      .then((response) => {
        this.restaurant = response.data.Restaurants;
        this.menuItems = response.data.Restaurants.Menu.Menu_Items || [];
        this.reviews = response.data.Restaurants.Reviews || [];
        this.Offres = response.data.Restaurants.Offres || [];
        this.isLoading = false;
        this.getratingPercentages();

        let data = response.data.Restaurants.Menu.Menu_Items;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].items.length; j++) {
            this.menu[data[i].items[j].id_menu_item] = { name: data[i].items[j].item_name ,value:data[i].items[j].id_menu_item};
            this.selectmenu = this.selectmenu.concat({ name: data[i].items[j].item_name ,value:data[i].items[j].id_menu_item})
          }
        }
      })
      .catch((error) => {
        console.log(error);
        this.isLoading = false;
      });
  },
  setup() {
    return {
      modules: [Navigation, Pagination, FreeMode],
    };
  },
};
</script>

<style scoped>
.p-inputnumber-button-up {
  background-color: #9eb23b !important;
  border-color: #9eb23b;
}

.p-inputnumber-button-down {
  background-color: #9eb23b !important;
  border-color: #9eb23b;
}

.p-inputtext:enabled:focus {
  border-color: #9eb23b !important;
  box-shadow: 0 0 0 0.2rem #eff7c8 !important;
}

.p-inputtext:enabled:hover {
  border-color: #9eb23b !important;
}

.p-button {
  border: 1px solid #c6cf98 !important;
}

.circle-wrap {
  /* margin: 150px auto; */
  width: 150px;
  height: 150px;
  background: #f9fafa;
  border-radius: 50%;
  border: 1px solid #cdcbd0;
}

.circle-wrap .circle .mask,
.circle-wrap .circle .fill {
  width: 150px;
  height: 150px;
  position: absolute;
  border-radius: 50%;
}

.circle-wrap .circle .mask {
  clip: rect(0px, 150px, 150px, 75px);
}

.circle-wrap .inside-circle {
  width: 122px;
  height: 122px;
  border-radius: 50%;
  background: #f9fafa;
  line-height: 120px;
  text-align: center;
  margin-top: 14px;
  margin-left: 14px;
  position: absolute;
  z-index: 100;
  font-weight: 700;
  font-size: 2em;
}

/* color animation */

/* 3rd progress bar */
.mask .fill {
  clip: rect(0px, 76px, 150px, 0px);
  background-color: #9eb23b;
}

.mask.full,
.circle .fill {
  animation: fill ease-in-out 3s;
  transform: rotate(135deg);
}

@keyframes fill {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(135deg);
  }
}

.custom-swal {
  border: 2px solid #000; /* Example: 2px solid black border */
  background-color: red;
}
</style>
