<template>
  <div class="card">
    <form
      @submit.prevent="submitEmail"
      class="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12"
    >
      <div>
        <h1 class="mb-1 text-3xl font-bold text-center text-[#9EB23B]">
          Mot de passe oublié
        </h1>
        <p
          class="text-[#9D9D9D] mb-4 font-normal text-base text-center leading-tight"
        >
          Connectez-vous en toute sécurité à votre compte en utilisant vos
          identifiants.
        </p>

        <input
          type="text"
          v-model="email"
          class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE] mb-4"
          name="email"
          placeholder="Entrer votre email"
          @input="handleChange"
        />

        <ProgressSpinner
          v-if="visible"
          class="flex align-items-center justify-content-center"
          style="width: 100%; height: 50px"
          strokeWidth="3"
          animationDuration=".5s"
          aria-label="Loading"
        />

        <button
          v-if="!visible"
          type="submit"
          class="w-full text-center py-3 rounded-lg bg-[#9EB23B] text-white text-xl font-bold focus:outline-none my-1"
        >
          Envoyer
        </button>
      </div>
      <p
        v-if="message"
        class="text-[#9D9D9D] mb-4 font-normal text-base leading-tight"
        :class="message.color"
      >
        {{ message.msg }}
      </p>
    </form>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      email: "",
      message: {},
    };
  },
  methods: {
    handleChange(event) {
      const { value } = event.target;
      this.email = value;
    },
    async submitEmail() {
      try {
        const params = {
          email: this.email,
        };
        const config = {
          headers: {
            Authorization: `Bearer vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy`,
          },
        };
        axios
          .post("https://www.miamdeal.com/api/forgot-password", null, {
            params,
            headers: config.headers,
          })
          .then((response) => {
            this.message = {
              color: "text-green-500",
              msg: response.data.message,
            };
          })
          .catch((err) => {
            this.message = {
              color: "text-red-500",
              msg: err.response.data.message,
            };
          });
      } catch (error) {
        console.error(error);
        this.message = "Une erreur s'est produite";
      }
    },
  },
};
</script>
<style scoped>
.card {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
}
</style>
