<template>
  <div class="col-span-1 lg:col-span-2 mt-8">
    <div class="card">
      <h5>Les derniers restaurants ajoutés</h5>
      <DataTable
        v-model:filters="filters"
        filterDisplay="menu"
        :value="Bookings"
        :rows="10"
        :paginator="true"
        responsiveLayout="scroll"
      >
        <template #header>
          <div class="flex justify-between">
            <Button
              type="button"
              icon="pi pi-filter-slash"
              label="Clear Filters"
              outlined
              @click="clearFilter()"
              style="color: black !important"
            />
            <div class="ml-1 grid min-[1081px]:grid-cols-2">
              <span class="p-input-icon-left m-1 max-[1080px]:w-full">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Keyword Search"
                  style="margin-right: 8px"
                  class="w-full"
                />
              </span>
              <Dropdown
                v-model="selectedstatus"
                @change="fetchFilteredData"
                :options="statusOptions"
                optionLabel="label"
                optionValue="value"
                placeholder="Select Status"
                class="m-1 w-full"
              />
            </div>
          </div>
        </template>
        <Column
          field="user_name"
          header="User Name"
          :sortable="true"
          style="width: 18%"
        >
          <template #body="slotProps">
            <!-- <Avatar image="/images/avatar/asiyajavayant.png" class="mr-2" size="large" shape="circle" /> -->
            <Avatar
              icon="pi pi-user"
              class="mr-3"
              size="large"
              shape="circle"
            />

            {{ slotProps.data.user_name }}
          </template>
        </Column>
        <Column
          field="restaurant_name"
          header="Restaurant name"
          :sortable="true"
          style="width: 15%"
        ></Column>
        <Column
          field="date_booking"
          header="Booking Date"
          :sortable="true"
          style="width: 10%"
        ></Column>
        <Column
          field="time_booking"
          header="Booking Time"
          :sortable="true"
          style="width: 15%"
        ></Column>
        <Column
          field="party_size"
          header="Booking Party size"
          :sortable="true"
          style="width: 8%"
        ></Column>
        <Column
          field="special_request"
          header="Special request"
          :sortable="true"
          style="width: 15%"
        ></Column>
        <Column
          field="created_at"
          header="Created at"
          :sortable="true"
          style="width: 15%"
        ></Column>

        <Column header="Status" style="width: 15%">
          <template #body="slotProps">
            <Tag
              :severity="
                slotProps.data.status === 1
                  ? 'success'
                  : slotProps.data.status === 0
                  ? 'warning'
                  : slotProps.data.status === 3
                  ? 'success'
                  : 'danger'
              "
              :value="
                slotProps.data.status === 1
                  ? 'Confirmed'
                  : slotProps.data.status === 0
                  ? 'Pending'
                  : slotProps.data.status === 3
                  ? 'il est venu'
                  : 'Canceled'
              "
            />
            <!-- canceled -->
          </template>
        </Column>
        <Column style="width: 5%">
          <template #header> Actions </template>
          <template #body="slotProps">
            <div class="flex items-center">
              <Button
                icon="pi pi-check"
                @click="() => confirm1(slotProps)"
                severity="success"
                text
                rounded
                aria-label="Filter"
              />
              <Button
                icon="pi pi-times"
                @click="() => confirm2(slotProps)"
                severity="danger"
                text
                rounded
                aria-label="Cancel"
              />
            </div>
          </template>
        </Column>
      </DataTable>
      <Toast />
      <ConfirmDialog></ConfirmDialog>
      <DynamicDialog style="width: 50vw" />
    </div>
  </div>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import Button from "primevue/button";
import ConfirmDialog from "primevue/confirmdialog";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import Row from "primevue/row";
import axios from "axios";
import OverlayPanel from "primevue/overlaypanel";
import Tag from "primevue/tag";
import InputText from "primevue/inputtext";
import { useConfirm } from "primevue/useconfirm";
import Avatar from "primevue/avatar";
import DynamicDialog from "primevue/dynamicdialog";
import Dropdown from "primevue/dropdown";

export default {
  components: {
    DataTable,
    Column,
    ColumnGroup,
    Row,
    Button,
    OverlayPanel,
    Tag,
    InputText,
    Avatar,
    ConfirmDialog,
    Toast,
    DynamicDialog,
    Dropdown,
  },
  data() {
    return {
      Bookings: [],
      baseurl: "https://www.miamdeal.com/images/",
      apiUrl: "https://www.miamdeal.com/api/Owner/get-all-booking",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      visible: true,
      menu: "",
      filters: {
        global: { value: null },
        status: { value: null },
      },
      statusOptions: [
        { label: "Confirmed", value: "Confirmed" },
        { label: "Pending", value: "Pending" },
        { label: "Canceled", value: "Canceled" },
        { label: "il est venu", value: "Venu" },
      ],
      selectedstatus: null,
      originaldata: [],
    };
  },
  mounted() {
    this.Getdata();
  },
  methods: {
    toggle(event, index) {
      const overlayRef = this.$refs["op" + index];
      overlayRef.toggle(event);
    },
    clearFilter() {
      this.filters = {
        global: { value: null },
      };
      this.selectedstatus = null;
      this.Bookings = this.originaldata; // Restore the original data
    },
    Getdata() {
      const token = localStorage.getItem("auth_token");

      console.log("haihatoken " + token);
      axios
        .get(this.apiUrl, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": token,
          },
        })
        .then((response) => {
          // console.log(response.data)
          this.Bookings = response.data.Bookings;
          this.originaldata = response.data.Bookings;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    fetchFilteredData() {
      let filteredData = this.originaldata; // Create a copy of the original array

      if (this.selectedstatus !== null) {
        // Filter the copy of the array based on the selected status
        filteredData = filteredData.filter((item) => {
          if (this.selectedstatus === "Confirmed") {
            return item.status === 1; // Approved
          } else if (this.selectedstatus === "Canceled") {
            return item.status === 2; // Rejected
          } else if (this.selectedstatus === "Pending") {
            return item.status === 0; // Rejected
          } else if (this.selectedstatus === "Venu") {
            return item.status === 3;
          } else {
            return true; // Show all when no specific status is selected
          }
        });
      }

      this.Bookings = filteredData; // Assign the filtered array back to the main data array
    },
  },
  setup() {
    const confirm = useConfirm();
    const toast = useToast();
    let changeStatusResponse = null;

    // const openDialog = (restaurant) => {
    //     const dialogRef = dialog.open(RestaurantDetails, {
    //         props: {
    //             restaurant: restaurant.id,

    //         }
    //     });
    // };

    const confirm1 = (slotProps) => {
      const { data } = slotProps;
      if (data.status == 0) {
        confirm.require({
          message: "Are you sure you want to proceed with ? " + data.user_name,
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-info",
          accept: () => {
            console.log("Accepted confirmation for ID:", data.id);

            ChangeStatus(data.id, 1).then((response) => {
              changeStatusResponse = response;
              console.log(changeStatusResponse);

              if (
                changeStatusResponse &&
                changeStatusResponse.status === true
              ) {
                data.status = 1;
                toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "The Status has been updated",
                  life: 500,
                });
              }
            });
          },
        });
      } else if (data.status == 1) {
        confirm.require({
          message: "Le client " + data.user_name + " a-t-il participé? ",
          header: "Confirmation",
          icon: "pi pi-exclamation-triangle",
          acceptClass: "p-button-info",
          accept: () => {
            ChangeStatus(data.id, 3).then((response) => {
              changeStatusResponse = response;

              if (
                changeStatusResponse &&
                changeStatusResponse.status === true
              ) {
                data.status = 3;
                toast.add({
                  severity: "info",
                  summary: "Confirmed",
                  detail: "The Status has been updated",
                  life: 500,
                });
              }
            });
          },
        });
      }
    };

    const confirm2 = (slotProps) => {
      const { data } = slotProps;
      confirm.require({
        message: "Do you want to delete this record?",
        header: "Delete Confirmation",
        icon: "pi pi-info-circle",
        acceptClass: "p-button-danger",
        accept: () => {
          console.log("Accepted confirmation for ID:", data.id);

          ChangeStatus(data.id, 2).then((response) => {
            changeStatusResponse = response;
            console.log(changeStatusResponse);

            if (changeStatusResponse && changeStatusResponse.status === true) {
              data.status = 2;

              toast.add({
                severity: "info",
                summary: "Confirmed",
                detail: "The Status has been updated",
                life: 500,
              });
            }
          });
        },
      });
    };

    const ChangeStatus = (id, status) => {
      const url = "https://www.miamdeal.com/api/Owner/change-Booking-Status";
      const token = localStorage.getItem("auth_token");
      const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "auth-token": token,
        },
      };

      const params = {
        idbooking: id,
        status: status,
      };

      return axios

        .post(url, null, { params, headers: config.headers })
        .then((response) => {
          return response.data;

          // this.Getdata();
        })

        .catch((error) => {
          console.log(error);
        });
    };

    return {
      confirm1,
      confirm2,
      ChangeStatus,
      changeStatusResponse,
      // Bind the method to the setup context
    };
  },
};
</script>
