<template>
  <div class="card">
    <h4 class="text-center">Add offres to your restaurant</h4>
    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 mb-5">
      <span class="p-input">
        <Dropdown
          v-model="selectedtype"
          :options="discount_type"
          optionLabel="name"
          placeholder="Select a discount type"
          class="w-full md:w-14rem"
        />
      </span>
      <span class="p-input">
        <Dropdown
          v-model="menu_id"
          :options="menuitems"
          optionLabel="name"
          placeholder="Select a menu items"
          class="w-full md:w-14rem"
        />
      </span>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
      <span class="p-input">
        <Calendar
          v-model="start_date"
          class="w-full mb-5"
          placeholder="Offre start date"
        />
      </span>
      <span class="p-input">
        <Calendar
          v-model="end_date"
          class="w-full mb-5"
          placeholder="Offre end date"
        />
      </span>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2">
      <span class="p-input">
        <Calendar
          v-model="start_time"
          class="w-full mb-5"
          placeholder="Offre start time"
          time-only
          :stepMinute="30"
        />
      </span>
      <span class="p-input">
        <Calendar
          v-model="end_time"
          class="w-full mb-5"
          placeholder="Offre end time"
          time-only
          :stepMinute="30"
        />
      </span>
    </div>

    <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-2 mb-5">
      <div class="flex flex-col">
        <span class="p-input">
          <InputNumber
            v-model="discount_value"
            inputId="integeronly"
            placeholder="Offre discount value"
            class="mb-2 w-full"
          />
        </span>
        <span class="p-input">
          <InputNumber
            v-model="min_uses"
            class="mb-2 w-full"
            inputId="integeronly"
            placeholder="Offre discount min uses"
          />
        </span>
        <span class="p-input">
          <InputNumber
            v-model="max_uses"
            class="w-full"
            inputId="integeronly"
            placeholder="Offre discount max uses"
          />
        </span>
      </div>

      <span class="p-input">
        <Textarea
          v-model="description"
          placeholder="Offre description"
          autoResize
          rows="6"
          cols="30"
          class="w-full md:w-14rem"
        />
      </span>
    </div>

    

    <ProgressSpinner
      v-if="visible"
      class="mt-10"
      style="width: 100%; height: 50px"
      strokeWidth="3"
      animationDuration=".5s"
      aria-label="Loading"
    />
    <div class="p-field text-center mt-10">
      <Button label="Add Offre" severity="info" @click="addOffre" />
    </div>
    <Toast />
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
import Dropdown from "primevue/dropdown";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import Calendar from "primevue/calendar";
import Button from "primevue/button";
import ProgressSpinner from "primevue/progressspinner";
import Toast from "primevue/toast";
import axios from "axios";

export default {
  components: {
    InputText,
    Dropdown,
    InputNumber,
    Textarea,
    Calendar,
    Button,
    ProgressSpinner,
    Toast,
  },
  data() {
    return {
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      discount_type: [{ name: "Fixed" }, { name: "Amount" }],
      offre_name: "",
      selectedtype: null,
      discount_value: null,
      description: "",
      start_date: null,
      end_date: null,
      start_time: null,
      end_time: null,
      min_uses: null,
      max_uses: null,
      id_restaurant: null,
      visible: false,
      menuitems: [],
      menu_id: null,
    };
  },
  computed: {
    // isFirstStepValid() {
    //     return (
    //         this.offre_name.trim() !== "" &&
    //         this.restemail.trim() !== "" &&
    //         this.restaddres.trim() !== "" &&
    //         this.restcity.trim() !== "" &&
    //         this.restphone.trim() !== "" &&
    //         this.restwebsite.trim() !== ""
    //     );
    // },
  },
  mounted() {
    const token = localStorage.getItem("auth_token");

    axios
      .get("https://www.miamdeal.com/api/Owner/getrestaubyowner", {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
          "auth-token": token,
        },
      })
      .then((response) => {
        this.id_restaurant = response.data.Restaurants.id;
        let data = response.data.Restaurants.Menu.Menu_Items;
        for (let i = 0; i < data.length; i++) {
          for (let j = 0; j < data[i].items.length; j++) {
            this.menuitems = this.menuitems.concat({
              name: data[i].items[j].item_name,
              value: data[i].items[j].id_menu_item,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    addOffre() {
      if (
        this.menu_id &&
        this.selectedtype &&
        this.description &&
        this.min_uses &&
        this.max_uses &&
        this.discount_value &&
        this.start_date &&
        this.start_time &&
        this.end_time &&
        this.end_date
      ) {
        this.visible = true;
        const newOffre = {
          discount_type: this.selectedtype.name,
          discount_value: this.discount_value,
          description: this.description,
          start_date: this.start_date.toISOString().substr(0, 10),
          end_date: this.end_date.toISOString().substr(0, 10),
          start_time: `${this.start_time.getHours()}:${this.start_time.getMinutes()}`,
          end_time:`${this.end_time.getHours()}:${this.end_time.getMinutes()}`,
          min_uses: this.min_uses,
          max_uses: this.max_uses,
          id_restaurant: this.id_restaurant,
          id_menu: this.menu_id,
        };

        const token = localStorage.getItem("auth_token");

        axios
          .post(
            "https://www.miamdeal.com/api/Restaurant/add-offre-restaurant",
            newOffre,
            {
              headers: {
                Authorization: `Bearer ${this.apiToken}`,
                "auth-token": token,
              },
            }
          )
          .then((response) => {
            this.visible = false;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Offre added successfully.",
              life: 3000,
            });
            this.offre_name = "";
            this.selectedtype = null;
            this.description = "";
            this.min_uses = null;
            this.start_date = null;
            this.end_date = null;
            this.discount_value = null;
          })
          .catch((error) => {
            console.log(error);
            this.visible = false;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please fill in all fields",
          life: 3000,
        });
      }
    },
  },
};
</script>
