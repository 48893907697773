<template>
  <div class="">
    <article>
      <header>
        <div class="progress">
          <div
            class="progress-step"
            :class="{ active: index === activeStep }"
            v-for="(step, index) in formSteps"
            :key="'step' + index"
          >
            {{ index + 1 }}
          </div>
        </div>
      </header>
      <section :class="animation">
        <h5>{{ formSteps[activeStep].title }}</h5>
        <div class="input-fields">
          <div
            class="input-container"
            v-for="(field, index) in formSteps[activeStep].fields"
            :key="'field' + index"
          >
            <template v-if="field.type === 'calendar'">
              <Calendar
                v-model="field.value"
                :minDate="new Date()"
                inline
                class="w-full"
              />
            </template>

            <template v-else-if="field.type === 'time'">
              <!--  <Calendar
                id="calendar-timeonly"
                v-model="field.value"
                :minDate="new Date()"
                timeOnly
                inline
                class="w-full"
                :stepMinute="30"
              /> -->

              <div class="center">
                <div class="tickets">
                  <div class="">
                    <div class="timings">
                      <div class="times">
                        <div
                          v-for="(time, index) in times"
                          :key="time"
                          style="margin: 10px"
                        >
                          <span v-if="checkdate(time)">
                            <input
                              type="radio"
                              name="time"
                              :value="time"
                              :id="time"
                              v-model="times_val"
                            />

                            <label
                              :for="time"
                              class="time relative inline-flex items-center p-3 text-sm font-medium text-center"
                            >
                              {{ time }}
                              <div
                                v-if="
                                  isTime(offre.start_time, time, offre.end_time)
                                "
                                class="absolute inline-flex items-center justify-center w-8 h-8 text-xs text-white bg-red-500 border-1 border-gray rounded-full -top-4 -end-4"
                              >
                                -{{ offre.discount_value
                                }}{{
                                  offre.discount_type == "Amount" ? "%" : "DH"
                                }}
                              </div>
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else-if="field.type === 'menu'">
              <Dropdown
                v-model="menu_id"
                :options="menu"
                optionLabel="name"
                placeholder="Select a menu items"
                class="w-full md:w-14rem"
              />
            </template>

            <template v-else-if="field.type === 'guests'">
              <!-- <InputNumber
                v-model="field.value"
                inputId="horizontal-buttons"
                showButtons
                buttonLayout="horizontal"
                :step="1"
                min="1"
                class="w-full"
                decrementButtonClass="p-button-info"
                incrementButtonClass="p-button-info"
              >
                <template #incrementbuttonicon>
                  <span class="pi pi-plus" />
                </template>
                <template #decrementbuttonicon>
                  <span class="pi pi-minus" />
                </template>
              </InputNumber> -->
              <div class="center">
                <div class="tickets">
                  <div class="">
                    <div class="timings">
                      <div class="times">
                        <div v-for="per in 10" :key="per" style="margin: 10px">
                          <input
                            type="radio"
                            name="personn"
                            :value="per"
                            :id="per"
                            v-model="persson_val"
                          />
                          <label
                            :for="per"
                            style="padding: 20px 40px"
                            class="time relative"
                            >{{ per }}
                            <div
                              v-if="isPerssone(per)"
                              class="absolute inline-flex items-center justify-center w-8 h-8 text-xs text-white bg-red-500 border-1 border-gray rounded-full -top-4 -end-4"
                            >
                              -{{ offre.discount_value
                              }}{{
                                offre.discount_type == "Amount" ? "%" : "DH"
                              }}
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="actions">
          <button v-if="activeStep > 0" @click="prev">breview</button>

          <button
            v-if="activeStep + 1 < formSteps.length - 1"
            @click="checkFields"
          >
            next
          </button>
          <button
            v-else-if="activeStep + 1 === formSteps.length - 1"
            @click="checkFields"
          >
            done
          </button>
        </div>
      </section>
    </article>
  </div>
  <Teleport to="body">
    <modal
      :show="showModal"
      @close="showModal = false"
      @open-login2="openLoginModal"
    >
    </modal>
    <modaltwo
      v-if="showLoginModal"
      @close="showLoginModal = false"
      @open-signup2="openSignUpModal"
    >
    </modaltwo>
  </Teleport>
</template>

<script>
import Calendar from "primevue/calendar";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import axios from "axios";
import Swal from "sweetalert2";
import Modal from "../components/ModalSignUp2.vue";
import Modaltwo from "../components/ModalSignIn2.vue";
import Dropdown from "primevue/dropdown";
import Button from "primevue/button";

export default {
  props: {
    menu: {
      type: Array,
    },
  },
  data: () => {
    return {
      showModal: false,
      showLoginModal: false,
      activeStep: 0,
      offre: { start_time: null, ehd_time: null },
      animation: "animate-in",
      menuitems: [],
      menu_id: "",
      times_val: null,
      persson_val: 1,
      times: [
        "00:00",
        "00:30",
        "01:00",
        "01:30",
        "02:00",
        "02:30",
        "03:00",
        "03:30",
        "04:00",
        "04:30",
        "05:00",
        "05:30",
        "06:00",
        "06:30",
        "07:00",
        "07:30",
        "08:00",
        "08:30",
        "09:00",
        "09:30",
        "10:00",
        "10:30",
        "11:00",
        "11:30",
        "12:00",
        "12:30",
        "13:00",
        "13:30",
        "14:00",
        "14:30",
        "15:00",
        "15:30",
        "16:00",
        "16:30",
        "17:00",
        "17:30",
        "18:00",
        "18:30",
        "19:00",
        "19:30",
        "20:00",
        "20:30",
        "21:00",
        "21:30",
        "22:00",
        "22:30",
        "23:00",
        "23:30",
      ],
      formSteps: [
        {
          title: "select date:",
          fields: [
            {
              type: "calendar",
              label: "Date:",
              value: new Date(),
              valid: true,
              pattern: /.+/,
            },
          ],
        },
        {
          title: "Select time",
          fields: [
            {
              type: "time",
              value: (() => {
                let date = new Date();
                date.setHours(date.getHours() + 1);
                return date;
              })(),
              valid: true,
              validator: function (value) {
                return value != null;
              },
            },
          ],
        },
        {
          title: "Number of guests",
          fields: [
            {
              type: "guests",
              value: 1,
              valid: true,
              validator: function (value) {
                return value >= 1 && value <= 10;
              },
            },
          ],
        },
        {
          title: "select menu items",
          fields: [
            {
              type: "menu",
              value: "",
              valid: true,
            },
          ],
        },
        {
          title: "Merci pour participer!",
        },
      ],
    };
  },
  components: {
    Calendar,
    InputNumber,
    Modal,
    Modaltwo,
    Dropdown,
  },
  mounted() {
    // Initialize times_val to the first valid time
    for (let time of this.times) {
      if (this.checkdate(time)) {
        this.times_val = time;
        break;
      }
    }
  },
  methods: {
    isPerssone(per) {
      if (!this.isTime(this.offre.start_time,this.times_val,this.offre.end_time)) return false;
      if (this.offre.min_uses > per || this.offre.max_uses < per) return false;
      return true;
    },
    isTime(startTime, currentTime, endTime) {
      if (this.offre.start_time == null || this.offre.end_time == null) {
        return false;
      } else {
        const [startHour, startMinute] = startTime.split(":").map(Number);
        const [endHour, endMinute] = endTime.split(":").map(Number);
        const [currentHour, currentMinute] = currentTime.split(":").map(Number);

        const startDate = new Date();
        startDate.setHours(startHour, startMinute, 0, 0);

        const endDate = new Date();
        endDate.setHours(endHour, endMinute, 0, 0);

        const currentDate = new Date();
        currentDate.setHours(currentHour, currentMinute, 0, 0);

        return startDate <= currentDate && currentDate <= endDate;
      }
    },
    checkdate(time) {
      const mysqlDateFormat = this.formSteps[0]["fields"][0]["value"]
        .toISOString()
        .substr(0, 10);
      const currentDate = new Date();
      const currentDateFormat = currentDate.toISOString().substring(0, 10);

      if (mysqlDateFormat === currentDateFormat) {
        let currentDate = new Date();
        let futureDate = new Date(currentDate.getTime() + 60 * 60 * 500);
        let [hours, minutes] = time.split(":").map(Number);
        let inputTime = new Date(
          currentDate.getFullYear(),
          currentDate.getMonth(),
          currentDate.getDate(),
          hours,
          minutes
        );
        return inputTime > futureDate;
      }
      return true;
    },

    openLoginModal() {
      this.showModal = false;
      this.showLoginModal = true;
    },
    openSignUpModal() {
      this.showModal = true;
      this.showLoginModal = false;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    handelClick() {
      if (localStorage.getItem("auth_token") != null) {
        this.Make_booking();
      } else {
        Swal.fire({
          title: "tu es sûre ?",
          text: "Soumettez votre compte pour compléter votre réservation",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          cancelButtonText: "Non!",
          confirmButtonText: "Oui, connectez-vous!",
        }).then((result) => {
          if (result.isConfirmed) {
            this.showModal = true;

            setTimeout(() => {
              Swal.fire({
                text: "Vous continuez à réserver ?",
                icon: "question",
                showCancelButton: true,
                background: "#dee2e6",
                backdrop: `transparent`,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#d33",
                cancelButtonText: "Anuller",
                confirmButtonText: "Contenu",
              }).then((result) => {
                if (result.isConfirmed) {
                  if (localStorage.getItem("auth_token") != null) {
                    this.Make_booking();
                  } else {
                    this.showModal = true;
                  }
                }
              });
            }, 1000);
          }
        });
      }
    },
    CheckOffre() {
      const mysqlDateFormat = this.formSteps[0]["fields"][0]["value"]
        .toISOString()
        .substr(0, 10);
      console.log(this.times_val);

      const restaurantId = this.$route.params.id;
      const url = "https://www.miamdeal.com/api/Booking/check-offre";
      const token = localStorage.getItem("auth_token");
      const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "auth-token": token,
        },
      };

      const params = {
        restaurant_id: restaurantId,
        date_booking: mysqlDateFormat,
        // time_booking: this.formSteps[1]["fields"][0]["value"],
        // party_size: this.persson_val,
        // special_request: this.menu_id["value"],
      };

      axios
        .post(url, null, { params, headers: config.headers })
        .then((response) => {
          if (
            response.data.offre &&
            Object.keys(response.data.offre).length !== 0
          ) {
            this.offre = response.data.offre;
          }
          this.nextStep();
        })

        .catch((error) => {
          console.log(error);
        });
    },
    Make_booking() {
      const mysqlDateFormat = this.formSteps[0]["fields"][0]["value"]
        .toISOString()
        .substr(0, 10);

      const restaurantId = this.$route.params.id;
      const url = "https://www.miamdeal.com/api/Booking/make-booking";
      const token = localStorage.getItem("auth_token");
      const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
      const config = {
        headers: {
          Authorization: `Bearer ${apiToken}`,
          "auth-token": token,
        },
      };

      const params = {
        restaurant_id: restaurantId,
        date_booking: mysqlDateFormat,
        // time_booking: this.formSteps[1]["fields"][0]["value"],
        time_booking: this.times_val,
        party_size: this.persson_val,
        special_request: this.menu_id["value"],
      };

      axios

        .post(url, null, { params, headers: config.headers })
        .then((response) => {
          // console.log(response.data);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
          this.nextStep();
        })

        .catch((error) => {
          console.log(error);
        });
    },
    prev() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep -= 1;
      }, 550);
    },
    nextStep() {
      this.animation = "animate-out";
      setTimeout(() => {
        this.animation = "animate-in";
        this.activeStep += 1;
      }, 550);
    },
    checkFields() {
      let valid = true;
      if (this.activeStep != 4) {
        this.formSteps[this.activeStep].fields.forEach((field) => {
          if (field.validator && !field.validator(field.value)) {
            valid = false;
            field.valid = false;
          } else {
            field.valid = true;
          }
        });
      }
      if (valid) {
        if (this.activeStep == 3) {
          this.handelClick();
        } else if (this.activeStep == 0) {
          this.CheckOffre();
        } else this.nextStep();
      } else {
        this.animation = "animate-wrong";
        setTimeout(() => {
          this.animation = "";
        }, 400);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
//@import url("https://fonts.googleapis.com/css?family=Noto+Sans&display=swap");

@mixin flexbox() {
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  @include flexbox();
  width: 100%;
  min-height: 100vh;
  font-family: "Noto Sans", sans-serif;
}

article {
  display: flex;
  margin: 10px;
  // width: calc(100% - 20px);
  // max-width: 720px;
  min-height: 550px;
  perspective: 1000px;

  header {
    @include flexbox();
    width: 60px;
    // height: 480px;
    background-color: #fff;
    border-right: 2px dotted #9eb23b;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 450px) {
    header {
      display: none;
    }
  }
  .progress-step {
    @include flexbox();
    position: relative;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 20px;
    color: #fff;
    background-color: #9eb23b;
    font-weight: bold;

    &.active {
      background-color: #9eb23b;

      ~ .progress-step {
        color: #555;
        background-color: #ccc;
      }

      ~ .progress-step::before {
        background-color: #ccc;
      }
    }

    &:before {
      content: "";
      position: absolute;
      top: -20px;
      width: 2px;
      height: 20px;
      background-color: #9eb23b;
      z-index: 10;
    }

    &:first-child::before {
      display: none;
    }
  }

  section {
    @include flexbox();
    flex-direction: column;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2), 0 10px 10px rgba(0, 0, 0, 0.2);

    h5 {
      font-size: 1.6rem;
      color: #9eb23b;
      margin: 0;
      padding: 20px;
    }

    .input-fields {
      @include flexbox();
      flex-direction: column;
      width: 100%;
    }

    .input-container {
      position: relative;
      padding: 30px 20px 20px 20px;
      width: calc(100% - 40px);
      max-width: 400px;

      input {
        position: relative;
        width: 100%;
        font-family: "Noto Sans", sans-serif;
        font-size: 1.35rem;
        outline: none;
        background: transparent;
        box-shadow: none;
        border: none;
        border-bottom: 2px dashed #9eb23b;

        &:valid + .input-label {
          top: 10px;
          left: 20px;
          font-size: 0.7rem;
          font-weight: normal;
          color: #999;
        }

        &.wrong-input + .input-label {
          color: #b92938;
        }
      }
    }

    .input-label {
      position: absolute;
      top: 32px;
      left: 20px;
      font-size: 1.35rem;
      pointer-events: none;
      transition: 0.2s ease-in-out;
    }

    .actions {
      margin-bottom: 3px;

      button {
        font-family: "Noto Sans", sans-serif;
        outline: none;
        border: none;
        color: #fff;
        background-color: #9eb23b;
        font-size: 1.35rem;
        padding: 5px 20px;
        margin: 2px;
        text-transform: uppercase;
        border-radius: 3px;
        cursor: pointer;
      }
    }
  }
}

.animate-in {
  transform-origin: left;
  animation: in 0.6s ease-in-out;
}

.animate-out {
  transform-origin: bottom left;
  animation: out 0.6s ease-in-out;
}

.animate-wrong {
  animation: wrong 0.4s ease-in-out;
}

@keyframes in {
  0% {
    opacity: 0;
    transform: rotateY(90deg);
  }
  100% {
    opacity: 1;
    transform: rotateY(0deg);
  }
}

@keyframes out {
  0% {
    transform: translateY(0px) rotate(0deg);
  }
  60% {
    transform: rotate(10deg);
  }
  100% {
    transform: translateY(1000px);
  }
}

@keyframes wrong {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(40px);
  }
  40% {
    transform: translateX(20px);
  }
  60% {
    transform: translateX(40px);
  }
  80% {
    transform: translateX(20px);
  }
  100% {
    transform: translateX(0);
  }
}
.center {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.tickets {
  width: 550px;
  height: fit-content;
  border: 0.4mm solid rgba(0, 0, 0, 0.08);
  border-radius: 3mm;
  box-sizing: border-box;
  padding: 10px;
  font-family: poppins;
  max-height: 96vh;
  overflow: auto;
  background: white;
  box-shadow: 0px 25px 50px -12px rgba(0, 0, 0, 0.25);
}
.ticket-selector {
  background: rgb(243, 243, 243);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  box-sizing: border-box;
  padding: 20px;
}
.head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 30px;
}

.seats {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 150px;
  position: relative;
}
.status {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.seats::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 220px;
  height: 7px;
  background: rgb(141, 198, 255);
  border-radius: 0 0 3mm 3mm;
  border-top: 0.3mm solid rgb(180, 180, 180);
}
.item {
  font-size: 12px;
  position: relative;
}
.item::before {
  content: "";
  position: absolute;
  top: 50%;
  left: -12px;
  transform: translate(0, -50%);
  width: 10px;
  height: 10px;
  background: rgb(255, 255, 255);
  outline: 0.2mm solid rgb(120, 120, 120);
  border-radius: 0.3mm;
}
.item:nth-child(2)::before {
  background: rgb(180, 180, 180);
  outline: none;
}
.item:nth-child(3)::before {
  background: rgb(28, 185, 120);
  outline: none;
}
.all-seats {
  display: grid;
  grid-template-columns: repeat(10, 1fr);
  grid-gap: 15px;
  margin: 60px 0;
  margin-top: 20px;
  position: relative;
}
.seat {
  width: 20px;
  height: 20px;
  background: white;
  border-radius: 0.5mm;
  outline: 0.3mm solid rgb(180, 180, 180);
  cursor: pointer;
}
.all-seats input:checked + label {
  background: rgb(28, 185, 120);
  outline: none;
}
.seat.booked {
  background: rgb(180, 180, 180);
  outline: none;
}
input {
  display: none;
}
.timings {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 7px;
}
.dates {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.dates-item {
  width: 50px;
  height: 40px;
  background: rgb(233, 233, 233);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10px 0;
  border-radius: 2mm;
  cursor: pointer;
}
.day {
  font-size: 12px;
}
.times {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 3;
  // overflow-x: auto;
  height: 250px;
}
.time {
  font-size: 14px;
  width: fit-content;
  padding: 10px 14px;
  background: rgb(233, 233, 233);
  border-radius: 2mm;
  cursor: pointer;
}
.timings input:checked + label {
  background: rgb(28, 185, 120);
  color: white;
}
.price {
  width: 100%;
  box-sizing: border-box;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.total {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
}
.total span {
  font-size: 11px;
  font-weight: 400;
}
.price button {
  background: rgb(60, 60, 60);
  color: white;
  font-family: poppins;
  font-size: 14px;
  padding: 7px 14px;
  border-radius: 2mm;
  outline: none;
  border: none;
  cursor: pointer;
}
</style>
