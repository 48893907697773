<template>
  <div class="bg-gray-100 min-h-screen">
    <div class="container mx-auto  py-8">
      <div class="bg-white rounded-lg shadow-md p-4 mb-8">
        <h2 class="text-2xl font-semibold mb-2">
          Welcome to Your Restaurant Space
        </h2>
        <p class="text-gray-600">
          Here, you can view and analyze your booking statistics.
        </p>
      </div>
      <h1 class="text-3xl font-bold mb-4 text-center">Booking Statistics</h1>
      <div class="card p-6">
        <h2 class="text-xl font-semibold mb-4">Booking Status</h2>
        <Chart
          :type="'pie'"
          :data="chartData"
          :options="chartOptions"
          class="w-full h-full"
          style="height: 300px"
        ></Chart>
      </div>
      <div class="bg-white rounded-lg shadow-md p-6">
        <h2 class="text-xl font-semibold mb-4">Monthly Bookings</h2>
        <Chart
          :type="'line'"
          :data="chartData2"
          :options="chartOptions2"
          class="w-full"
        ></Chart>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from "vue";
import Chart from "primevue/chart";
import axios from "axios";

const apiUrl = "https://www.miamdeal.com/api/Owner/get-all-booking"; // Replace with your API endpoint
const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy"; // Replace with your API token

const chartData = ref();
const chartOptions = ref({
  cutout: "70%",
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      beginAtZero: true,
    },
  },
});

const chartData2 = ref();
const chartOptions2 = ref({
  plugins: {
    legend: {
      display: true,
      position: "top",
    },
  },
  scales: {
    x: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
    y: {
      beginAtZero: true,
      ticks: {
        stepSize: 1,
      },
    },
  },
});

onMounted(GetData);

function GetData() {
  const token = localStorage.getItem("auth_token");
  console.log("Token: " + token);

  axios
    .get(apiUrl, {
      headers: {
        Authorization: `Bearer ${apiToken}`,
        "auth-token": token,
      },
    })
    .then((response) => {
      const bookingsData = response.data.Bookings;

      let confirmedCount = 0;
      let pendingCount = 0;
      let canceledCount = 0;
      let totalCount = bookingsData.length;

      for (const booking of bookingsData) {
        if (booking.status === 1) {
          confirmedCount++;
        } else if (booking.status === 0) {
          pendingCount++;
        } else if (booking.status === 2) {
          canceledCount++;
        }
      }

      const bookings = {
        labels: ["Confirmed", "Pending", "Canceled"],
        datasets: [
          {
            label: "Bookings",
            data: [confirmedCount, pendingCount, canceledCount],
            backgroundColor: ["#66bb6a", "#ffa726", "#ef5350"],
          },
        ],
      };

      chartData.value = bookings;

      // Chart 2 - Total bookings on each month
      const monthlyBookings = {
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
        ],
        datasets: [
          {
            label: "Total Bookings",
            fill: false,
            borderColor: "#4fc3f7",
            tension: 0.4,
            data: getMonthlyBookingsData(bookingsData),
          },
        ],
      };

      chartData2.value = monthlyBookings;
    })
    .catch((error) => {
      console.log(error);
    });
}

function getMonthlyBookingsData(bookingsData) {
  const monthlyData = Array(7).fill(0); // Initialize an array of 7 elements with 0s

  for (const booking of bookingsData) {
    const monthIndex = new Date(booking.created_at).getMonth();
    monthlyData[monthIndex]++;
  }

  return monthlyData;
}
</script>
