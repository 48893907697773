<template>
  <div class="register">
    <a href="/newrestaurant">enregistrer mon etablissement</a>
  </div>
  <header class="sticky top-0 bg-white z-50 shadow-md">
    <div
      class="max-w-screen-2xl mx-auto px-8 sm:px-8 md:px-6 lg:px-12"
      :class="{ 'py-6': !scrolled, 'py-3': scrolled }"
    >
      <div
        class="grid grid-cols-2 md:grid-cols-3 gap-0 md:gap-4 justify-items-center"
      >
        <RouterLink to="/" class="flex items-center">
          <div class="max-w-full flex">
            <img
              src="../assets/MiamDeallogo.svg"
              alt="Logo"
              class="w-full max-w-full"
            />
          </div>
        </RouterLink>

        <div class="hidden md:block"></div>
        <div class="flex flex-row">
          <!-- <button
            @click="visible = true"
            class="flex items-center justify-center p-1 md:p-2 rounded-md ml-2 bg-transparent text-black font-semibold text-base md:text-lg"
          >
            <font-awesome-icon
              class="icon text-[#9EB23B]"
              icon="fa-solid fa-mobile"
            />
            Application
          </button> -->
          <div v-if="loggedIn">
            <Button
              type="button"
              id="user-modal"
              style="
                background-color: white;
                color: #9eb23b;
                --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1),
                  0 4px 6px -4px rgb(0 0 0 / 0.1);
                --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color),
                  0 4px 6px -4px var(--tw-shadow-color);
                box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
                  var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
                border: 0px !important;
              "
              class="flex flex-row items-center p-2 pl-3"
              @click="toggle"
              aria-haspopup="true"
              aria-controls="overlay_menu"
            >
              <Avatar
                icon="pi pi-user"
                class="mr-2"
                size="large"
                shape="circle"
              />

              <div class="flex flex-col items-center">
                <span class="font-semibold">Mon Compte</span>
                <!-- <span class="font-semibold text-black">{{ user.name }}</span> -->
                <!-- <span class="text-sm">{{ user.role }}</span> -->
              </div>
            </Button>
            <Menu ref="menu" id="overlay_menu" :model="items" :popup="true" />
          </div>

          <button
            id="show-modal"
            class="flex items-center justify-center px-3 py-2 md:px-4 md:py-3 border-none rounded-md ml-2 bg-white border-2 border-green-500 text-[#9EB23B] font-semibold shadow-lg text-base md:text-lg"
            :class="{ 'md:py-3': !scrolled, 'md:py-1': scrolled }"
            @click="showModal = true"
            v-else
          >
            <font-awesome-icon
              class="icon text-[#9EB23B]"
              icon="fa-solid fa-user-large"
            />
            Connexion
          </button>

          <Teleport to="body">
            <modal
              :show="showModal"
              @close="showModal = false"
              @open-login="openLoginModal"
            >
            </modal>
            <modaltwo
              v-if="showLoginModal"
              @close="showLoginModal = false"
              @open-signup="openSignUpModal"
            >
            </modaltwo>
          </Teleport>
        </div>
      </div>

      <DynamicDialog />
    </div>
  </header>
</template>

<script>
import Modal from "./ModalSignUp.vue";
import Modaltwo from "./ModalSignIn.vue";
import Bookingcard from "./BookingCard.vue";

import { mapState, mapMutations, mapActions } from "vuex";
import { useDialog } from "primevue/usedialog";
import DynamicDialog from "primevue/dynamicdialog";
import { ref } from "vue";
import Swal from "sweetalert2";
import axios from "axios";

export default {
  components: {
    Modal,
    Modaltwo,
    DynamicDialog,
  },
  data() {
    return {
      scrolled: false,
      showModal: false,
      showLoginModal: false,
      visible: false,
      userName: "",
      userRole: "",
      items: [
        {
          label: "Profile",
          icon: "pi pi-user",
          command: () => {
            this.$toast.add({
              severity: "success",
              summary: "Updated",
              detail: "Data Updated",
              life: 3000,
            });
          },
        },
        {
          label: "My Booking",
          icon: "pi pi-user",
          command: () => {
            this.showRestaurantDetails();
          },
        },
        {
          label: "Reglages",
          icon: "pi pi-fw pi-cog",
          command: () => {
            this.$toast.add({
              severity: "warn",
              summary: "Delete",
              detail: "Data Deleted",
              life: 3000,
            });
          },
        },
        {
          label: "Se déconnecter",
          icon: "pi pi-sign-out",
          command: () => {
            this.logout();
          },
        },
      ],
      restaurant_id: null,
      user_id: null,
      star: 5,
      star1:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" aria-hidden="true" focusable="false" font-size="l" color="brandPrimary.500" mx="xxs" class="fill-current text-[#9EB23B]" > <path fill-rule="evenodd" d="M6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z" ></path> </svg>',
      star2:
        '<svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 24 24" aria-hidden="true" focusable="false" font-size="l" color="brandPrimary.500" mx="xxs" class="fill-current text-[#9EB23B]" > <path fill-rule="evenodd" d="M12 2.908 9.903 9.656h-7s-.005.017.003.024l5.591 4.261-2.444 7.063c-.005.015-.003.017-.006.02L12 16.788l5.953 4.306c-.003-.003-.001-.004-.005-.019l-2.096-7.135 5.242-4.261c.008-.007.002-.024.004-.024h-7.001L12 2.908ZM6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z" ></path> </svg>',
    };
  },
  created() {
    const token = localStorage.getItem("auth_token");
    // console.log("token "+token)
    this.setLoggedIn(!!token);
    if (token) {
      this.$store.dispatch("authusers/loadUser"); // Load user object from local storage
    }

    if (this.loggedIn && this.user.role !== "customer") {
      const dashboardItem = {
        label: "Dashboard",
        icon: "pi pi-fw pi-home",
        to: "/dashboard",
      };

      if (this.user.role == "owner") {
        dashboardItem.to = "/dashboard/ownerdashboard";
      }

      // Check if the "Dashboard" item already exists in the items array
      const existingDashboardItem = this.items.find(
        (item) => item.label === "Dashboard"
      );
      if (!existingDashboardItem) {
        this.items.splice(1, 0, dashboardItem);
      }
    } else {
      // Remove the "Dashboard" item from the items array if it exists
      const dashboardIndex = this.items.findIndex(
        (item) => item.label === "Dashboard"
      );
      if (dashboardIndex !== -1) {
        this.items.splice(dashboardIndex, 1);
      }
      // const Registeritem = {
      //   label: "Register your restaurant",
      //   icon: "pi pi-fw pi-home",
      //   to: "/newrestaurant",
      // };
      // const existingDashboardItem = this.items.find(
      //   (item) => item.label === "Register your restaurant"
      // );
      // if (!existingDashboardItem) {
      //   this.items.splice(1, 0, Registeritem);
      // }
    }
  },
  mounted() {
    this.Reviews();
    // this.showRatingModal();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    Reviews() {
      if (this.loggedIn) {
        const url =
          "https://www.miamdeal.com/api/Booking/get-booking-not-review";
        const token = localStorage.getItem("auth_token");
        const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
        const config = {
          headers: {
            Authorization: `Bearer ${apiToken}`,
            "auth-token": token,
          },
        };
        axios.post(url, null, { headers: config.headers }).then((response) => {
          if (response.data.Booking != null) {
            console.log(response.data.Booking);
            this.showRatingModal(response.data.Booking.name);
            this.restaurant_id = response.data.Booking.restaurant_id;
            this.user_id = response.data.Booking.user_id;
          }
        });
      }
    },
    starsHtml() {
      let html = '<div class="flex" id="starhtml" >';
      for (let i = 1; i <= 5; i++) {
        html += `<span class="star">${
          i <= this.star ? this.star1 : this.star2
        }</span>`;
      }
      return html + "</div>";
    },
    review(index) {
      this.star = index + 1;
      // document.getElementById("starhtml").innerHTML = this.starsHtml();
      // Swal.getContent().querySelector('#starhtml').innerHTML = this.starsHtml();
    },
    attachStarClickListeners() {
      // This method reattaches the click listeners to the stars
      const stars = Swal.getHtmlContainer().querySelectorAll(".star");
      stars.forEach((star, index) => {
        star.addEventListener("click", () => {
          this.review(index);
          Swal.getHtmlContainer().innerHTML = this.starsHtml();
          this.attachStarClickListeners();
        });
      });
    },
    showRatingModal(restau) {
      Swal.fire({
        title: "Quelle est votre évaluation de ce restaurant ? " + restau,
        html: this.starsHtml(),
        input: "textarea",
        inputPlaceholder: "Type your comment here...",
        inputAttributes: {
          "aria-label": "Type your comment here",
          required: true,
        },
        inputValidator: (value) => {
          if (!value) {
            return "You need to write something!";
          }
        },
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Save",
        denyButtonText: `Ignorer`,
        didOpen: () => {
          const stars = Swal.getHtmlContainer().querySelectorAll(".star");
          stars.forEach((star, index) => {
            star.addEventListener("click", () => {
              this.review(index);
              // Update the HTML content of the Swal after a star is clicked
              Swal.getHtmlContainer().innerHTML = this.starsHtml();
              // Reattach event listeners as the HTML content has been replaced
              this.attachStarClickListeners();
            });
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          const comment = result.value;
          const url = "https://www.miamdeal.com/api/User/make-review";
          const token = localStorage.getItem("auth_token");
          const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
          const config = {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              "auth-token": token,
            },
          };
          const params = {
            restaurant_id: this.restaurant_id,
            rating: this.star,
            comment: comment,
            id_user: this.user_id,
          };
          axios
            .post(url, null, { params, headers: config.headers })
            .then((response) => {
              Swal.fire("Saved!", "", "success");
            });
        } else if (result.isDenied) {
          const comment = result.value;
          const url = "https://www.miamdeal.com/api/User/ignorer-review";
          const token = localStorage.getItem("auth_token");
          const apiToken = "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy";
          const config = {
            headers: {
              Authorization: `Bearer ${apiToken}`,
              "auth-token": token,
            },
          };
          const params = {
            restaurant_id: this.restaurant_id,
            id_user: this.user_id,
          };
          axios
            .post(url, null, { params, headers: config.headers })
            .then((response) => {
              Swal.fire(response.data.message, "", "info");
            });
        }
      });
    },
    handleScroll() {
      this.scrolled = window.scrollY > 0;
    },
    openLoginModal() {
      this.showModal = false;
      this.showLoginModal = true;
    },
    openSignUpModal() {
      this.showModal = true;
      this.showLoginModal = false;
    },
    toggle(event) {
      this.$refs.menu.toggle(event);
    },
    ...mapMutations("authusers", ["setLoggedIn"]),
    ...mapActions("authusers", ["clearUser"]),
    logout() {
      localStorage.removeItem("auth_token");
      this.clearUser();
      this.setLoggedIn(false);
    },
  },
  computed: {
    ...mapState("authusers", ["loggedIn"]),
    ...mapState("authusers", ["user"]),
  },
  setup() {
    const dialog = useDialog();
    const dialogVisible = ref(false);

    const showRestaurantDetails = () => {
      dialog.open(Bookingcard, {
        props: {
          style: {
            width: "55vw",
          },
          breakpoints: {
            "960px": "75vw",
            "640px": "90vw",
          },
          modal: true,
        },
      });
    };

    return {
      showRestaurantDetails,
      dialogVisible,
      // ... other returned values from the setup function
    };
  },
};
</script>

<style scoped>
.icon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.register{
  text-align: end;
  padding: 5px 15px;
}
.register a{
  color: #9EB23B;
text-transform: uppercase;
}
.register a:hover{
  text-decoration: underline;
  /* background-color: red; */
}
</style>
