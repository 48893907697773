import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import Motdepasse from '../views/Motdepasse.vue'
import TestView from '../views/TestView.vue'
import RestaurantDetails from '../views/RestaurantDetails.vue'
import Restaurants from '../views/Restaurants.vue'
import Dashboard from '../views/MiamdealDashboard.vue'
import Changestatus from '../views/Changestatus.vue'
import UserSection from '../views/Admin_userSection.vue'
import Newrestaurant from '../views/Addnew_restaurant.vue'
import Ownerdashboard from '../views/Ownerdashboard.vue'
import ResetPassword from '../views/reset-password.vue'


import restaurantimages from '../views/Add-images.vue'
import menus from '../views/Add-menus.vue'
import menuItems from '../views/Add-menu-items.vue'


import aboutdata from '../views/Add-aboutdata.vue'
import offredata from '../views/Add-offredata.vue'
import ownerbooking from '../views/Ownerbooking.vue'


import AppLayout from '@/layout/AppLayout.vue';



const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/mot-passe-oublie',
    name: 'Mot_passe_oublie',
    component: Motdepasse
  },
  {
    path: '/reset-password',
    name: 'reset_password',
    component: ResetPassword
  },
  {
    path: '/test',
    name: 'test',
    component: TestView
  },  {
    path: '/restaurants',
    name: 'restaurants',
    component: Restaurants
  },
  {
    path: '/restaurants/:id',
    name: 'restaurant-details',
    component: RestaurantDetails
  },
  {
    path: '/newrestaurant',
    name: 'restaurant-new',
    component: Newrestaurant,
    children: [
      {
        path: '/personal',
        name: 'restaurant-personal',
        component: aboutdata
      },
      //  {
      //   path: '/restaurant',
      //   name: 'restaurant-info',
      //   component: offredata
      // },
    ]

  },
  {
    path: '/dashboard',
    name: 'admin-dashboard',
    meta: { hideHeaderFooter: true },
    component: AppLayout,
    children: [
      {
        path: '',
        name: 'teste',
        component: Dashboard
      },
      {
        path: 'changestatus',
        name: 'admin-action',
        component: Changestatus
      },
      {
        path: 'User_section',
        name: 'admin-action-onuser',
        component: UserSection
      },
      {
        path: 'add-offre',
        name: 'admin-offre',
        component: offredata
      },
      {
        path: 'add-images',
        name: 'restaurant-images',
        component: restaurantimages
      },
      {
        path: 'add-menus',
        name: 'restaurant-menus',
        component: menus
      },
      {
        path: 'add-menu-items',
        name: 'restaurant-menu-items',
        component: menuItems
      },
      {
        path: 'ownerdashboard',
        name: 'owner-dash',
        component: Ownerdashboard
      },
      {
        path: 'bookinghandling',
        name: 'owner-booking',
        component: ownerbooking
      }
    ]
  }

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
