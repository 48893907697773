<template>
  <div class="grid grid-cols-1 sm:grid-cols-2 container  gap-8 mx-auto  my-14 card"> <!--sm:mx-7 md:mx-24 lg:mx-60 xl:mx-90-->
    <div v-if="!requestSent">
      <div>
        <h2 class="text-[#9EB23B] text-4xl font-bold mb-4">Let's Chat</h2>
        <p class="text-gray-600 mb-10">
          Are you a restaurant owner? Fill in your personal details, and a
          member of our team will get back to you shortly.
        </p>
               <span class="p-input">
          <InputText
            v-model="fullname"
            placeholder="Entrer votre nom complet"
            class="w-full p-3 border rounded"
          />
        </span>
        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 my-6">
          <span class="p-input">
            <InputText
              v-model="restname"
              placeholder="Restaurant Name"
              class="w-full p-3 border rounded"
            />
          </span>
          <span class="p-input">
            <InputText
              v-model="restemail"
              placeholder="Email"
              class="w-full p-3 border rounded"
            />
          </span>
        </div>
        <span class="p-input">
          <InputText
            v-model="restaddress"
            placeholder="Restaurant Address"
            class="w-full p-3 border rounded"
          />
        </span>
        <div
          class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 gap-4 mb-6 mt-5"
        >
          <span class="p-input">
            <InputText
              v-model="restcity"
              placeholder="Restaurant City"
              class="w-full p-3 border rounded"
            />
          </span>
          <span class="p-input">
            <InputMask
              id="basic"
              v-model="restphone"
              class="w-full p-3 border rounded"
              mask="0699999999"
              placeholder="Restaurant Phone"
            />
          </span>
        </div>
        <span class="p-input">
          <InputText
            v-model="restwebsite"
            placeholder="Restaurant Website"
            class="w-full p-3 border rounded"
          />
        </span>
        <div class="flex justify-center mt-6">
          <ProgressSpinner
            v-if="visible"
            style="width: 100%; height: 50px"
            strokeWidth="3"
            animationDuration=".5s"
            aria-label="Loading"
          />
          <button
            v-if="!visible"
            @click="registerrestaurant()"
            class="bg-[#9EB23B] hover:bg-[#7E9727] text-white font-bold py-3 px-6 rounded-full w-full"
          >
            Register
          </button>
        </div>
      </div>
    </div>
    <div v-else>
      <!-- Request Sent Message -->
      <div class="flex flex-col items-center justify-center mt-20">
        <h2 class="text-[#9EB23B] text-4xl font-bold mb-4">Request Sent</h2>
        <p class="text-gray-600 mb-10 text-center text-xl">
          Your request to register a new restaurant has been submitted. Our team
          will review it, and you will receive a response via email soon.
        </p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-16 w-16 text-[#9EB23B] animate-bounce"
          viewBox="0 0 20 20"
          fill="currentColor"
        >
          <path
            fill-rule="evenodd"
            d="M2.05 5.05a7.027 7.027 0 000 9.9l4.95 4.95a1.5 1.5 0 102.12-2.12l-4.95-4.95a4.999 4.999 0 010-7.07 4.999 4.999 0 017.07 0l2.83 2.83a1.5 1.5 0 102.12-2.12l-2.83-2.83a7.027 7.027 0 00-9.9 0z"
            clip-rule="evenodd"
          />
        </svg>
        <a
          href="/"
          class="mt-6 bg-[#9EB23B] hover:bg-[#7E9727] text-white font-bold py-3 px-6 rounded-full"
        >
          Go to Home
        </a>
      </div>
    </div>
    <div>
      <div class="flex justify-items-center items-center h-full">
        <img src="../assets/Waiters-amico.svg" alt="" class="w-full h-auto hidden sm:block"/>
      </div>
    </div>
  </div>
  <Toast />
</template>

<script>
import Steps from "primevue/steps";
import InputText from "primevue/inputtext";
import InputMask from "primevue/inputmask";
import ProgressSpinner from "primevue/progressspinner";
import Toast from "primevue/toast";
import axios from "axios";

export default {
  components: {
    Steps,
    InputText,
    InputMask,
    ProgressSpinner,
    Toast,
  },
  data() {
    return {
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      apiUrl: "https://www.miamdeal.com/api/Restaurant/add-new-Restaurant",
      visible: false,
      restname: null,
      restemail: null,
      restaddress: null,
      restcity: null,
      restwebsite: null,
      restphone: null,
      requestSent: false,
      fullname:null
    };
  },
  methods: {
    registerrestaurant() {
      this.visible = true;

      if (
        this.restname &&
        this.restemail &&
        this.restaddress &&
        this.restcity &&
        this.fullname &&
        this.restphone
      ) {
        const token = localStorage.getItem("auth_token");
        const config = {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": token,
          },
        };

        const params = {
          name: this.restname,
          address: this.restaddress,
          city: this.restcity,
          country: "Maroc",
          phone: this.restphone,
          email: this.restemail,
          website: this.restwebsite,
          fullname: this.fullname,
        };

        axios
          .post(this.apiUrl, null, { params, headers: config.headers })
          .then((response) => {
            console.log(response.data);
            if (response.data.status === true) {
              this.$toast.add({
                severity: "success",
                summary: "Success",
                detail: "Your request has been sended to the admin ",
                life: 3000,
              });
              this.visible = false;
              this.restaddress =
                this.restcity =
                this.restemail =
                this.restname =
                this.restphone =
                this.fullname =
                  null;
              this.requestSent = true;
            } else {
              this.visible = false;
              this.$toast.add({
                severity: "error",
                summary: "Error",
                detail: response.data.message,
                life: 3000,
              });
            }
          })
          .catch((error) => {
            this.visible = false;
            console.log(error);
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please fill in all fields",
          life: 3000,
        });
        this.visible = false;
      }
    },
  },
};
</script>

<style scoped>
/* ... existing styles ... */

.animate-bounce {
  animation: bounce 1s infinite;
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(-20%);
  }

  50% {
    transform: translateY(0);
  }
}
</style>
