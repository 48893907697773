<template>
  <div class="py-10">
    <div class="mx-auto max-w-screen-xl px-2 py-4 mt-4">
      <p class="text-3xl text-black font-normal mb-8">Comment ça marche ?</p>
      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        <!-- Best Choice -->
        <div class="flex flex-col items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
            class="fill-current text-[#9EB23B] mb-4"
          >
            <g fill-rule="evenodd">
              <path
                d="M12 1.5c5.799 0 10.5 4.701 10.5 10.5S17.799 22.5 12 22.5 1.5 17.799 1.5 12 6.201 1.5 12 1.5Zm0 1.313a9.187 9.187 0 1 0 0 18.374 9.187 9.187 0 0 0 0-18.375Z"
              ></path>
              <path
                d="M9.307 17.074c-.586.417-1.349-.172-1.115-.847l1.107-3.199-2.507-1.91c-.559-.426-.263-1.329.442-1.329h3.128l.938-3.02c.215-.692 1.185-.692 1.4 0l.938 3.02h3.128c.693 0 .997.878.46 1.315l-2.367 1.924.96 3.27c.197.671-.56 1.222-1.13.81l-2.693-1.947-2.69 1.913Z"
              ></path>
            </g>
          </svg>
          <h3 class="text-xl font-medium text-gray-800 text-center">
            Best Choice
          </h3>
          <p class="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce id
            lorem vitae turpis finibus viverra.
          </p>
        </div>

        <!-- User Reviews -->
        <div class="flex flex-col items-start">
          <div class="flex flex-row mb-6">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              font-size="l"
              color="brandPrimary.500"
              mx="xxs"
              class="fill-current text-[#9EB23B]"
            >
              <path
                fill-rule="evenodd"
                d="M6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              font-size="l"
              color="brandPrimary.500"
              mx="xxs"
              class="fill-current text-[#9EB23B]"
            >
              <path
                fill-rule="evenodd"
                d="M6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              font-size="l"
              color="brandPrimary.500"
              mx="xxs"
              class="fill-current text-[#9EB23B]"
            >
              <path
                fill-rule="evenodd"
                d="M6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              font-size="l"
              color="brandPrimary.500"
              mx="xxs"
              class="fill-current text-[#9EB23B]"
            >
              <path
                fill-rule="evenodd"
                d="M6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z"
              ></path>
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 24 24"
              aria-hidden="true"
              focusable="false"
              font-size="l"
              color="brandPrimary.500"
              mx="xxs"
              class="fill-current text-[#9EB23B]"
            >
              <path
                fill-rule="evenodd"
                d="M12 2.908 9.903 9.656h-7s-.005.017.003.024l5.591 4.261-2.444 7.063c-.005.015-.003.017-.006.02L12 16.788l5.953 4.306c-.003-.003-.001-.004-.005-.019l-2.096-7.135 5.242-4.261c.008-.007.002-.024.004-.024h-7.001L12 2.908ZM6.858 22.164c-1.118.795-2.575-.328-2.128-1.618l2.113-6.106-4.785-3.647c-1.068-.814-.503-2.537.844-2.537h5.97l1.792-5.767c.41-1.319 2.262-1.319 2.672 0l1.792 5.767h5.97c1.324 0 1.903 1.677.879 2.51l-4.52 3.674 1.834 6.242c.376 1.282-1.07 2.334-2.158 1.547l-5.14-3.718-5.135 3.653Z"
              ></path>
            </svg>
          </div>

          <h3 class="text-xl font-medium text-gray-800 text-center">
            User Reviews
          </h3>
          <p class="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce id
            lorem vitae turpis finibus viverra.
          </p>
        </div>

        <!-- Exclusive Benefits -->
        <div class="flex flex-col items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
            class="fill-current text-[#9EB23B] mb-4"
          >
            <g fill-rule="evenodd">
              <path
                d="M12 3c-4.963 0-9 4.037-9 9s4.037 9 9 9 9-4.037 9-9-4.037-9-9-9m0 19.5C6.21 22.5 1.5 17.79 1.5 12S6.21 1.5 12 1.5 22.5 6.21 22.5 12 17.79 22.5 12 22.5"
              ></path>
              <path
                d="M14.5 14a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Zm2.273-6.773a.75.75 0 0 1 0 1.06l-8.485 8.486a.75.75 0 0 1-1.061-1.06l8.485-8.486a.75.75 0 0 1 1.061 0ZM9.5 7a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3Z"
              ></path>
            </g>
          </svg>
          <h3 class="text-xl font-medium text-gray-800 text-center">
            Exclusive Benefits
          </h3>
          <p class="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce id
            lorem vitae turpis finibus viverra.
          </p>
        </div>

        <!-- Easy Reservation -->
        <div class="flex flex-col items-start">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="50"
            height="50"
            viewBox="0 0 24 24"
            aria-hidden="true"
            focusable="false"
            class="mb-4 fill-current text-[#9EB23B]"
          >
            <g fill-rule="evenodd">
              <path
                d="M17.25.75a.75.75 0 0 1 .75.75V3h3.75a.75.75 0 0 1 .75.75v18a.75.75 0 0 1-.75.75H2.25a.75.75 0 0 1-.75-.75v-18A.75.75 0 0 1 2.25 3H6V1.5a.75.75 0 0 1 1.5 0V3h9V1.5a.75.75 0 0 1 .75-.75ZM21 9H3v12h18V9ZM6 4.5H3v3h18v-3h-3v.75a.75.75 0 0 1-1.5 0V4.5h-9v.75a.75.75 0 0 1-1.5 0V4.5Z"
              ></path>
              <path
                d="M10.313 18.813a.753.753 0 0 1-.531-.22l-3.188-3.187a.752.752 0 0 1 0-1.062.752.752 0 0 1 1.062 0l2.657 2.658 6.031-6.033a.752.752 0 0 1 1.062 0 .752.752 0 0 1 0 1.062l-6.562 6.563a.753.753 0 0 1-.531.219"
              ></path>
            </g>
          </svg>
          <h3 class="text-xl font-medium text-gray-800 text-center">
            Easy Reservation
          </h3>
          <p class="text-gray-600 mt-2">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce id
            lorem vitae turpis finibus viverra.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped></style>
