<template>
  <div>
    <h1 class="text-2xl font-semibold mb-8 text-center">My Bookings</h1>

    <div class="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
      <div
        v-for="booking in bookings"
        :key="booking.id"
        class="bg-white shadow-lg rounded-lg overflow-hidden"
      >
        <img
          v-if="booking.image"
          :src="baseurl + booking.image"
          alt="Restaurant"
          class="h-48 w-full object-cover"
        />
        <img
          v-else
          :src="baseurl + '/Restaurants/defaultimg.png'"
          class="h-48 w-full object-cover"
        />

        <div class="p-4">
          <h2 class="text-xl font-semibold mb-2">
            {{ booking.restaurant_name }}
          </h2>
          <p class="text-gray-700">
            <strong>Date:</strong> {{ booking.date_booking }}
          </p>
          <p class="text-gray-700">
            <strong>Time:</strong> {{ booking.time_booking }}
          </p>
          <p class="text-gray-700">
            <strong>Party Size:</strong> {{ booking.party_size }} Guests
          </p>
          <p class="text-gray-700">
            <strong>Special Request:</strong>
            {{
              booking.special_request === null
                ? "no special request was mentioned"
                : booking.special_request
            }}
          </p>
          <div class="flex justify-between items-center mt-4">
            <span
              v-if="booking.status === 0"
              class="text-gray-600 text-sm flex items-center"
            >
              <i class="pi pi-clock mr-1"></i> Pending
            </span>
            <span
              v-else-if="booking.status === 1"
              class="text-gray-600 text-sm flex items-center bg-green-500 text-white px-2 py-1 rounded"
            >
              <i class="pi pi-check mr-1"></i> Confirmed
            </span>
            <span
              v-else
              class="text-gray-600 text-sm flex items-center bg-red-500 text-white px-2 py-1 rounded"
            >
              <i class="pi pi pi-times mr-1"></i> Canceled
            </span>

            <button
              v-if="booking.status === 0"
              @click="cancelBooking(booking.id)"
              class="px-4 py-2 bg-red-500 text-white font-semibold rounded hover:bg-red-600"
            >
              Cancel Booking
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";

export default {
  data() {
    return {
      bookings: [],
      baseurl: "https://www.miamdeal.com/images/",
      apiUrl: "https://www.miamdeal.com/api/User/get-booking",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
    };
  },
  methods: {
    Getdata() {
      axios
        .get(this.apiUrl, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": localStorage.getItem("auth_token"),
          },
        })
        .then((response) => {
          this.bookings = response.data.Booking;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getStatusLabel(status) {
      return status === 0 ? "Pending" : "Confirmed";
    },
    cancelBooking(bookingId) {
      const url =
        "https://www.miamdeal.com/api/User/cancel-booking/" + bookingId;
      const token = localStorage.getItem("auth_token");
      const config = {
        headers: {
          Authorization: `Bearer ${this.apiToken}`,
          "auth-token": token,
        },
      };

      axios

        .post(url, null, { headers: config.headers })
        .then((response) => {
          console.log(response.data);
          const Toast = Swal.mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.onmouseenter = Swal.stopTimer;
              toast.onmouseleave = Swal.resumeTimer;
            },
          });
          Toast.fire({
            icon: "success",
            title: response.data.message,
          });
        })

        .catch((error) => {
          console.log(error);
        });

      // Make an API call to cancel the booking using the bookingId
      // Replace this with your actual API call
      console.log("Cancel booking:", bookingId);
    },
  },
  mounted() {
    this.Getdata();
  },
};
</script>

<style>
/* Add any custom styles here */
</style>
