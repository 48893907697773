<template>
  <div class="card">
    <div class="grid grid-cols-1 min-[1300px]:grid-cols-3 gap-0">
      <div class="flex flex-col gap-5 col-span-1">
        <h4 class="text-left">Add menu items to {{ menu }}</h4>
        <div class="w-full sm:w-1/2 min-[1300px]:w-full mx-auto">
          <span>
            <Dropdown
              v-model="selectedCategory"
              width="100%"
              :options="categories"
              optionValue="id"
              optionLabel="category_name"
              placeholder="Select a Catgeory"
              class="w-full"
            />
          </span>
          <span class="p-float-label my-2">
            <InputText v-model="item_name" width="100%" type="text" class="w-full "/>
            <label for="name">Item name</label>
          </span>

          <span class="p-float-label mb-2">
            <Textarea v-model="description" rows="5" cols="30" class="w-full"/>
            <label for="description">Item Desciption</label>
          </span>

          <span class="p-float-label">
            <InputText v-model="price" width="100%" type="text" class="w-full"/>
            <label for="price">Item Price</label>
          </span>

          <ProgressSpinner
            v-if="visible"
            class="mt-12"
            style="width: 20%; height: 50px; margin-left: 0; margin-right: 0"
            strokeWidth="3"
            animationDuration=".5s"
            aria-label="Loading"
          />

          <div v-if="!visible" class="p-field mt-12">
            <Button label="Add menu item" @click="addMenuItem" severity="info" class="w-full"/>
          </div>

          <Toast />
        </div>
      </div>
      <div class="max-[1300px]:mt-5 col-span-2">
        <DataTable
          v-model:expandedRows="expandedRows"
          v-model:filters="filters"
          :value="computedMenuItems"
          dataKey="id_category"
          @rowExpand="onRowExpand"
          :rows="10"
          :paginator="true"
          responsiveLayout="scroll"
          @rowCollapse="onRowCollapse"
          tableStyle="min-width: 60rem"
        >
          <template #header>
            <div class="flex justify-content-end">
              <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText
                  v-model="filters['global'].value"
                  placeholder="Keyword Search"
                  style="margin-right: 8px"
                  class="w-full"
                />
              </span>
            </div>
          </template>
          <Column expander style="width: 5rem" />
          <Column field="category_name" header="Category Name" :sortable="true">
            <template #body="slotProps">
              {{ slotProps.data.category_name }}
            </template>
          </Column>

          <template #expansion="slotProps">
            <div class="p-3">
              <h5>Items for {{ slotProps.data.category_name }}</h5>
              <DataTable :value="slotProps.data.items">
                <Column field="item_name" header="Item Name"></Column>
                <Column field="description" header="Description"></Column>
                <Column field="price" header="Price"> </Column>
              </DataTable>
            </div>
          </template>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import InputText from "primevue/inputtext";
import Textarea from "primevue/textarea";
import { ref } from "vue";
import { useToast } from "primevue/usetoast";
import Toast from "primevue/toast";
import ProgressSpinner from "primevue/progressspinner";

export default {
  data() {
    return {
      apiUrl2: "https://www.miamdeal.com/api/Owner/getrestaubyowner",
      apiUrl3: "https://www.miamdeal.com/api/Owner/getallcategories",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      menuItems: [],
      menu: null,
      menu_id: null,
      id_restaurant: null,
      selectedCategory: [],
      item_name: null,
      description: null,
      price: null,
      categories: [],
      visible: false,
    };
  },
  components: {
    Button,
    Column,
    DataTable,
    Dropdown,
    InputText,
    Textarea,
    Toast,
    ProgressSpinner,
  },
  mounted() {
    this.fetchmenuItems();
    this.fetchcategories();
  },

  methods: {
    fetchmenuItems() {
      const token = localStorage.getItem("auth_token");

      axios
        .get(this.apiUrl2, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": token,
          },
        })
        .then((response) => {
          this.menuItems = response.data.Restaurants.Menu.Menu_Items;
          this.id_restaurant = response.data.Restaurants.id;
          this.menu_id = response.data.Restaurants.Menu.id_menu;
          this.menu = response.data.Restaurants.Menu.menu_name;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    fetchcategories() {
      const token = localStorage.getItem("auth_token");

      axios
        .get(this.apiUrl3, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": token,
          },
        })
        .then((response) => {
          this.categories = response.data.Categories;
        })
        .catch((error) => {
          console.error(error);
        });
    },

    addMenuItem() {
      if (
        this.selectedCategory &&
        this.item_name &&
        this.description &&
        this.price
      ) {
        this.visible = true;

        const newItem = {
          menu_id: this.menu_id,
          id_restaurant: this.id_restaurant,
          items: [
            {
              item_name: this.item_name,
              description: this.description,
              price: this.price,
              id_category: this.selectedCategory,
            },
          ],
        };

        const authToken = localStorage.getItem("auth_token");

        const headers = {
          Authorization: `Bearer ${this.apiToken}`,
          "auth-token": authToken,
        };

        axios
          .post(
            "https://www.miamdeal.com/api/Owner/add-Menu_Items-Restaurant",
            newItem,
            { headers }
          )
          .then((response) => {
            this.fetchmenuItems();
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Menu item added successfully",
              life: 3000,
            });

            this.selectedCategory = null;
            this.item_name = null;
            this.description = null;
            this.price = null;
            this.visible = false;
          })
          .catch((error) => {
            console.error(error);
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: "Error adding menu item",
              life: 3000,
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please fill in all fields",
          life: 3000,
        });
        this.visible = false;
      }
    },
  },
  computed: {
    computedMenuItems() {
      return this.menuItems.slice(); // Create a shallow copy of menuItems
    },
  },
  setup() {
    const expandedRows = ref([]);
    const toast = useToast();

    const filters = ref({
      global: { value: null },
    });

    const onRowExpand = (event) => {
      // toast.add({ severity: 'info', summary: 'User Expanded', detail: event.data.name, life: 3000 });
    };

    const onRowCollapse = (event) => {
      // toast.add({ severity: 'success', summary: 'User Collapsed', detail: event.data.name, life: 3000 });
    };

    const expandAll = () => {
      expandedRows.value = users.value.map((user) => user.id);
    };

    const collapseAll = () => {
      expandedRows.value = [];
    };
    return {
      expandedRows,
      toast,
      filters,
      onRowExpand,
      onRowCollapse,
      expandAll,
      collapseAll,
    };
  },
};
</script>

<style scoped></style>
