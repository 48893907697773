<script>
import axios from "axios";
import Dialog from "primevue/dialog";
import ProgressSpinner from "primevue/progressspinner";
import InlineMessage from "primevue/inlinemessage";
import { mapMutations, mapActions } from "vuex";
import { router } from "@/router";

export default {
  components: {
    Dialog,
    ProgressSpinner,
    InlineMessage,
  },
  data() {
    return {
      email: "",
      password: "",
      error: "",
      apiUrl: "https://www.miamdeal.com/api/User/auth",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      visible: false,
      errors: false,
      errorDialogVisible: false,
    };
  },
  methods: {
    ...mapMutations("authusers", ["setLoggedIn", "setUser"]),
    login() {
      if (this.email === "") {
        this.errors = true;
      } else if (this.password === "") {
        this.errors = true;
        this.error = "Password is requierd";
      } else {
        this.visible = true;
        const config = {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
          },
        };

        const params = {
          email: this.email,
          password: this.password,
        };

        axios
          .post(this.apiUrl, null, { params, headers: config.headers })
          .then((response) => {
            if (response.data.status) {
              // console.log(response.data);
              // console.log("valid");
              // const user = response.data.user; // Store the user object
              const auth_token = response.data.user.auth_token;
              localStorage.setItem("auth_token", auth_token);
              this.setLoggedIn(true);
              const user = {
                name: response.data.user.name,
                role: response.data.user.role,
              };
              this.setUser(user);
              localStorage.setItem("user", JSON.stringify(user));

              if (user.role == "admin") {
                this.$router.push({ path: "/dashboard" });
              } else if (user.role == "owner") {
                this.$router.push({ path: "/dashboard/ownerdashboard" });
              }
              this.$emit("close");
            } else {
              // console.log("invalid")
              // console.log(response.data)
              this.error = "Invalid credentials";
              this.errorDialogVisible = true;
            }

            this.visible = false;
          })
          .catch((error) => {
            this.visible = false;
            this.errorDialogVisible = true;
            console.log(error);
          });

        // console.log(this.email + '\n' + this.password);
      }
    },
    hidemodel() {
      this.$emit("close");
      this.$router.push({
        path: "/mot-passe-oublie",
      });
    },
  },
};
</script>

<template>
  <Transition name="modaltwo">
    <div class="modal-mask">
      <div
        class="h-fit bg-white px-5 py-16 rounded-xl m-auto relative"
        style="
          box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
          transition: all 0.3s ease;
        "
      >
        <button class="absolute top-0 right-0" @click="$emit('close')">
          <img class="w-12 h-12" src="../assets/Xmark.svg" />
        </button>
        <!-- Left column container with background-->
        <div
          class="g-6 flex h-full flex-wrap items-center justify-center lg:justify-evenly"
        >
          <div
            class="shrink-1 mb-12 grow-0 basis-auto md:mb-0 md:w-9/12 md:shrink-0 lg:w-6/12 xl:w-6/12"
          >
            <img
              src="../assets/img_signup.png"
              class="w-96"
              alt="Sample image"
            />
          </div>

          <!-- Right column container -->
          <div class="mb-12 md:mb-0 md:w-8/12 lg:w-5/12 xl:w-5/12">
            <h1 class="mb-1 text-3xl font-bold text-center text-[#9EB23B]">
              Bienvenue de nouveau!
            </h1>
            <p
              class="text-[#9D9D9D] mb-4 font-normal text-base text-center leading-tight"
            >
              Connectez-vous en toute sécurité à votre compte en utilisant vos
              identifiants.
            </p>

            <input
              type="text"
              v-model="email"
              class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE] mb-4"
              name="email"
              placeholder="Entrer votre email"
            />
            <p class="text-[#9D9D9D] mb-4 font-normal text-base leading-tight">
              <InlineMessage v-if="errors && email === ''"
                >Email is requierd</InlineMessage
              >
            </p>

            <input
              type="password"
              v-model="password"
              class="bg-[#F5F5F5] block border border-[#9EB23B] w-full p-3 rounded-lg placeholder:text-[#C0BEBE]"
              name="password"
              placeholder="Entrer votre mot de passe"
            />

            <p
              class="text-right mb-4 text-sm text-[#ADBA6E] underline underline-offset-2"
              @click="hidemodel"
            >
              Mot de passe oublié?
            </p>
            <p class="text-[#9D9D9D] mb-4 font-normal text-base leading-tight">
              <InlineMessage v-if="errors && password === ''"
                >Password is requierd</InlineMessage
              >
            </p>

            <ProgressSpinner
              v-if="visible"
              class="flex align-items-center justify-content-center"
              style="width: 100%; height: 50px"
              strokeWidth="3"
              animationDuration=".5s"
              aria-label="Loading"
            />

            <button
              v-if="!visible"
              type="submit"
              @click="login"
              class="w-full text-center py-3 rounded-lg bg-[#9EB23B] text-white text-xl font-bold focus:outline-none my-1"
            >
              Connexion
            </button>
                     <!-- ----------------------------------- -->

            <div class="inline-flex items-center justify-center w-full relative">
              <hr class="w-64 h-1 my-5 bg-gray-200 border-0 rounded" />
              <div class="absolute px-4 -translate-x-1/2 bg-white left-1/2">
                or
              </div>
            </div>

            <!-- ----------------------------------- -->
            <button
            @click="$emit('open-signup')"
              class="w-full text-center py-3 rounded-lg bg-gray-300 text-[#9EB23B]  font-bold focus:outline-none my-1"
            >
              S'inscrire
            </button>
            <!-- <p class="text-center text-[#9D9D9D] text-sm font-normal">
              Vous n'avez pas de compte?
              <button
                @click="$emit('open-signup')"
                class="text-[#9EB23B] underline underline-offset-2 text-base"
              >
                S'inscrire
              </button>
            </p> -->
          </div>
        </div>
        <div class="dialog-overlay" v-if="errorDialogVisible">
          <div
            class="dialog-container flex flex-col items-center justify-center gap-2"
          >
            <p class="text-red-500 text-lg">{{ error }}</p>
            <button
              @click="errorDialogVisible = false"
              class="bg-red-500 text-white px-4 py-2 rounded-xl"
            >
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<style scoped>
.modal-mask {
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  transition: opacity 0.3s ease;
  overflow-y: auto;
}

.modal-enter-from {
  opacity: 0;
}

.modal-leave-to {
  opacity: 0;
}

.dialog-container {
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  padding: 1rem;
}

.modal-enter-from .modal-container,
.modal-leave-to .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.p-progress-spinner-circle {
  stroke-dasharray: 89, 200;
  stroke-dashoffset: 0;
  stroke: #9eb23b !important;
  animation: p-progress-spinner-dash 1.5s ease-in-out infinite,
    6s ease-in-out infinite !important;
  stroke-linecap: round;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-container {
  position: relative;
  z-index: 9999;
  width: 300px;
  height: 100px;
}
</style>
