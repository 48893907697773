<template>
  <div class="card">
    <div v-if="menuexists">
      <h4>Your restaurant already have a menu called {{ menu_name }}.</h4>
      <h4>
        Go to
        <RouterLink class="text-blue-500" to="/dashboard/add-menu-items"
          >Restaurant Menu Items</RouterLink
        >
        section to add items to your menu.
      </h4>
    </div>

    <div class="gap-0" v-else>
      <h4 class="text-center">Add a menu to your restaurant</h4>
      <div class="grid grid-cols-3">
        <div class="flex flex-col gap-2">
          <label for="menu_name">Menu name</label>
          <InputText
            id="menu_name"
            v-model="name"
            class="w-3/4"
            aria-describedby="menu-help"
          />
          <small id="menu-help">Enter a name for your menu.</small>
        </div>

        <div class="flex flex-col gap-2">
          <label for="description">Menu description</label>
          <Textarea
            id="description"
            v-model="description"
            aria-describedby="description-help"
            class="w-3/4"
            rows="5"
            cols="30"
          />
          <small id="description-help"
            >Enter a description for your menu.</small
          >
        </div>

        <div class="">
          <label for="image">Choose an Image for the menu</label>
          <FileUpload
            mode="basic"
            name="demo[]"
            url="./upload.php"
            class="w-3/4"
            accept="image/*"
            :maxFileSize="1000000"
            @upload="onUpload"
          />
        </div>
      </div>
      <ProgressSpinner
        v-if="visible"
        class="mt-10"
        style="width: 100%; height: 50px"
        strokeWidth="3"
        animationDuration=".5s"
        aria-label="Loading"
      />
      <div class="p-field text-center mt-10">
        <Button label="Add menu" severity="info" @click="addMenu" />
      </div>
      <Toast />
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Button from "primevue/button";
import Column from "primevue/column";
import DataTable from "primevue/datatable";
import Dropdown from "primevue/dropdown";
import FileUpload from "primevue/fileupload";
import InputText from "primevue/inputtext";
import ProgressSpinner from "primevue/progressspinner";
import Textarea from "primevue/textarea";
import Toast from "primevue/toast";

export default {
  data() {
    return {
      apiUrl: "https://www.miamdeal.com/api/Owner/getrestaubyowner",
      apiToken: "vU6BhF23VjDIagNTSTUvrp1W8KsPuCeLy",
      restaurant: null,
      name: "",
      description: "",
      image: null,
      menus: null,
      restaurantId: null,
      menu_name: null,
      menuexists: false,
      visible: false,
    };
  },
  components: {
    Dropdown,
    InputText,
    Button,
    Textarea,
    FileUpload,
    DataTable,
    Column,
    ProgressSpinner,
    Toast,
  },
  created() {},
  mounted() {
    this.fetchdata();
  },
  methods: {
    onUpload() {
      console.log("uploaded");
    },
    fetchdata() {
      const token = localStorage.getItem("auth_token");

      axios
        .get(this.apiUrl, {
          headers: {
            Authorization: `Bearer ${this.apiToken}`,
            "auth-token": token,
          },
        })
        .then((response) => {
          if (response.data.Restaurants.Menu !== null) {
            this.menuexists = true;
            this.menu_name = response.data.Restaurants.Menu.menu_name;
          }
          this.restaurant = response.data.Restaurants.name;
          this.restaurantId = response.data.Restaurants.id;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    addMenu() {
      if (this.name && this.description) {
        this.visible = true;
        const newMenu = {
          id_restaurant: this.restaurantId,
          name: this.name,
          description: this.description,
          // image_menu: this.image,
        };

        const token = localStorage.getItem("auth_token");

        axios
          .post(
            "https://www.miamdeal.com/api/Owner/add-Menu-Restaurant",
            newMenu,
            {
              headers: {
                Authorization: `Bearer ${this.apiToken}`,
                "auth-token": token,
              },
            }
          )
          .then((response) => {
            this.name = null;
            this.description = null;
            this.visible = false;
            this.$toast.add({
              severity: "success",
              summary: "Success",
              detail: "Menu added successfully.",
              life: 3000,
            });
            this.fetchdata();
            // this.image = null;
          })
          .catch((error) => {
            console.log(error);
            this.visible = false;
            this.$toast.add({
              severity: "error",
              summary: "Error",
              detail: error,
              life: 3000,
            });
          });
      } else {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Please fill in all fields",
          life: 3000,
        });
      }
    },
  },
};
</script>

<style scoped></style>
